@import './mixin.less';

.@{stepper-prefix-cls} {
    --sdesign-input-number-border-color: #EFEFEF;

    &-group-addon {
        background: none;
        color: var(--sdesign-color-grey-5);
    }

    &-handler-wrap {
        opacity: 1;

        .@{stepper-prefix-cls}-handler {
            height: 50% !important;

            .@{stepper-prefix-cls}-handler-up-inner,
            .@{stepper-prefix-cls}-handler-down-inner {
                font-size: 12px;
            }

            .@{stepper-prefix-cls}-handler-up-inner {
                margin-top: -6px;
            }
        }
    }

    &-handler-up-inner,
    &-handler-down-inner {
        color: var(--sdesign-font-color-5);
    }

    &:not(&-sm) {
        .@{stepper-prefix-cls}-handler-wrap {
            &>span {
                padding: 0 var(--sdesign-spacing-small);
            }
        }
    }

    &-handler {
        color: var(--sdesign-font-color-5);
        border-color: var(--sdesign-input-number-border-color);

        &:hover {
            color: var(--color-primary-hover);

            .@{stepper-prefix-cls}-handler-wrap {
                .@{stepper-prefix-cls}-handler-down {
                    border-color: var(--sdesign-color-border-default);
                }
            }
        }

        &:active {
            color: var(--color-primary-click);
        }
    }

    &-affix-wrapper input.@{stepper-prefix-cls}-input {
        padding-right: var(--sdesign-spacing-extra-huge);
    }

    &-group-wrapper {
        .@{stepper-prefix-cls} {
            &:first-child {
                border-top-right-radius: var(--sdesign-radius-medium);
                border-bottom-right-radius: var(--sdesign-radius-medium);
            }

            input {
                padding-right: calc(var(--sdesign-spacing-extra-huge) + 12px);
            }
        }

        .@{stepper-prefix-cls}+.@{stepper-prefix-cls}-group-addon {
            position: absolute;
            right: 32px;
            border: none;
            line-height: 32px;
        }

        .@{stepper-prefix-cls}-group-addon+.@{stepper-prefix-cls} {
            input {
                padding-right: var(--sdesign-spacing-extra-huge);
            }
        }
    }

    &-prefix {
        color: var(--sdesign-color-grey-5);
    }

    /* 禁用状态下的样式设置 */
    &-disabled {
        border-color: var(--sdesign-input-number-border-color);

        &:hover {
            border-color: var(--sdesign-input-number-border-color);

            .@{stepper-prefix-cls}-handler-wrap {

                .@{stepper-prefix-cls}-handler-down {
                    border-color: var(--sdesign-input-number-border-color);
                }
            }
        }

        .@{stepper-prefix-cls}-handler-wrap {
            display: block;
            background-color: var(--sdesign-color-fill-3);
            color: var(--sdesign-font-color-2);

            * {
                cursor: not-allowed;
                color: var(--sdesign-font-color-5);
            }

            /* 覆盖hover变化 */
            .@{stepper-prefix-cls}-handler {
                height: 50% !important;
            }
        }
    }
}