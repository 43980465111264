.@{ant-prefix}-switch {
  background-color: var(--sdesign-color-grey-3);
}

.@{ant-prefix}-switch-checked {
  background-color: var(--sdesign-color-brand-6);
}

.@{ant-prefix}-switch-disabled {
  background-color: var(--sdesign-color-grey-2);
}

.@{ant-prefix}-switch-checked.@{ant-prefix}-switch-disabled {
  background-color: var(--sdesign-color-brand-2);

}

.@{ant-prefix}-click-animating-node {
  animation: none;
  box-shadow: none;
}