@tab-prefix-cls: ~'@{ant-prefix}-tabs';

.@{tab-prefix-cls} {
    &-small {
        >.@{tab-prefix-cls}-nav {

            .@{tab-prefix-cls}-tab {
                padding: @tabs-horizontal-padding-sm;
                font-size: var(--sdesign-font-size-extra-small);
            }
        }
    }

    &-large {
        >.@{tab-prefix-cls}-nav {
            .@{tab-prefix-cls}-tab {
                padding: @tabs-horizontal-padding-lg;
                font-size: @tabs-title-font-size-lg;

                &-active {
                    .@{tab-prefix-cls}-tab-btn {
                        color: var(--sdesign-color-grey-11);
                    }
                }
            }
        }
    }

    &-card {
        &.@{tab-prefix-cls}-small {
            >.@{tab-prefix-cls}-nav {
                .@{tab-prefix-cls}-tab {
                    padding: @tabs-card-horizontal-padding-sm;
                }
            }
        }

        &.@{tab-prefix-cls}-large {
            >.@{tab-prefix-cls}-nav {
                .@{tab-prefix-cls}-tab {
                    padding: @tabs-card-horizontal-padding-lg;
                }
            }
        }
    }

    &.@{tab-prefix-cls}-small {
        >.@{tab-prefix-cls}-nav {
            .@{tab-prefix-cls}-nav-wrap {
                height: 32px;
            }
        }
    }
}