// @import "~@sdesign/theme/index.css";

@tag-prefix-cls: ~'@{ant-prefix}-tag';

.@{tag-prefix-cls} {
    padding: 0 var(--sdesign-spacing-regular);
    font-size: var(--sdesign-font-size-extra-small);
    font-weight: var(--sdesign-font-weight-regular);
    border-radius: var(--sdesign-radius-medium);

    &-close-icon {
        margin-left: var(--sdesign-spacing-regular);
    }

    &-success {
        background-color: var(--sdesign-color-green-1);
        color: var(--sdesign-color-green-6);
    }

    &-warning {
        background-color: var(--sdesign-color-orange-1);
        color: var(--sdesign-color-orange-6);
    }

    &-error {
        background-color: var(--sdesign-color-red-1);
        color: var(--sdesign-color-red-6);
    }

    &-processing {
        background-color: var(--sdesign-color-blue-1);
        color: var(--sdesign-color-blue-6);
    }

    // To ensure that a space will be placed between character and `Icon`.
    >.anticon+span,
    >span+.anticon {
        margin-left: var(--sdesign-spacing-regular);
    }
}