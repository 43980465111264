.border-radius(@size: 4px) {
  border-radius: @size;
}

.border-radius-angle(@tl: 0, @tr: 0, @br: 0, @bl: 0) {
  border-radius: @tl @tr @br @bl;
}

.standard-border(@size: 1px, @style: solid, @color: var(--sdesign-color-border-default)) {
  border: @size @style @color;
}

// 宽高等长
.square(@size: 0px) {
  width: @size;
  height: @size;
}

.ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}