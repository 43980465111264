@tab-prefix-cls: ~'@{ant-prefix}-tabs';

.@{tab-prefix-cls}-card {

  &.hidden-close-btn {
    .@{tab-prefix-cls}-tab {
      &-remove {
        display: none;
      }
    }
  }

  .@{tab-prefix-cls}-tab.@{tab-prefix-cls}-tab-active {
    .@{tab-prefix-cls}-tab-btn {
      color: var(--sdesign-color-brand-6);
    }
  }

  .@{tab-prefix-cls}-tab {
    &-btn {
      // 限制8个字
      max-width: 127px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  .@{tab-prefix-cls}-nav-wrap {
    height: 40px !important;
  }

  >.@{tab-prefix-cls}-nav {
    padding-top: var(--sdesign-spacing-large) !important;

    &::before {
      display: none;
    }
  }

  >.@{tab-prefix-cls}-nav,
  >div>.@{tab-prefix-cls}-nav {
    .@{tab-prefix-cls}-tab {
      margin: 0;
      padding: @tabs-card-horizontal-padding;
      background: @tabs-card-head-background;
      border: @border-width-base @border-style-base @border-color-split;
      transition: all @animation-duration-slow @ease-in-out;
      margin-left: var(--sdesign-spacing-basic) !important;

      &:first-of-type {
        margin-left: 0 !important;
      }

      &-active {
        color: @tabs-card-active-color;
        background: @component-background;
      }
    }

    .@{tab-prefix-cls}-ink-bar {
      visibility: hidden;
    }
  }

  // ========================== Top & Bottom ==========================
  &.@{tab-prefix-cls}-top,
  &.@{tab-prefix-cls}-bottom {

    >.@{tab-prefix-cls}-nav,
    >div>.@{tab-prefix-cls}-nav {
      .@{tab-prefix-cls}-tab+.@{tab-prefix-cls}-tab {
        margin-left: @tabs-card-gutter;
      }
    }
  }

  &.@{tab-prefix-cls}-top {

    >.@{tab-prefix-cls}-nav,
    >div>.@{tab-prefix-cls}-nav {
      .@{tab-prefix-cls}-tab {
        border-radius: @border-radius-base @border-radius-base 0 0;

        &-active {
          border-bottom-color: @component-background;
        }
      }
    }
  }

  &.@{tab-prefix-cls}-bottom {

    >.@{tab-prefix-cls}-nav,
    >div>.@{tab-prefix-cls}-nav {
      .@{tab-prefix-cls}-tab {
        border-radius: 0 0 @border-radius-base @border-radius-base;

        &-active {
          border-top-color: @component-background;
        }
      }
    }
  }

  // ========================== Left & Right ==========================
  &.@{tab-prefix-cls}-left,
  &.@{tab-prefix-cls}-right {

    >.@{tab-prefix-cls}-nav,
    >div>.@{tab-prefix-cls}-nav {
      .@{tab-prefix-cls}-tab+.@{tab-prefix-cls}-tab {
        margin-top: @tabs-card-gutter;
      }
    }
  }

  &.@{tab-prefix-cls}-left {

    >.@{tab-prefix-cls}-nav,
    >div>.@{tab-prefix-cls}-nav {
      .@{tab-prefix-cls}-tab {
        border-radius: @border-radius-base 0 0 @border-radius-base;

        &-active {
          border-right-color: @component-background;
        }
      }
    }
  }

  &.@{tab-prefix-cls}-right {

    >.@{tab-prefix-cls}-nav,
    >div>.@{tab-prefix-cls}-nav {
      .@{tab-prefix-cls}-tab {
        border-radius: 0 @border-radius-base @border-radius-base 0;

        &-active {
          border-left-color: @component-background;
        }
      }
    }
  }
}