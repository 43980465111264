.@{ant-prefix}-form {
  font-size: var(--sdesign-font-size-regular);
  font-weight: var(--sdesign-font-weight-regular);

  .@{ant-prefix}-form-item {
    line-height: 0;
  }

  .@{ant-prefix}-form-item-label-left {
    // 文字左对齐
    .@{ant-prefix}-form-item-no-colon {
      padding-left: 10px;
    }

    .@{ant-prefix}-form-item-required {
      padding-left: 0px;
    }
  }

  .@{ant-prefix}-form-item {
    margin-bottom: var(--sdesign-spacing-large);
  }

  .@{ant-prefix}-form-item-label {
    & > label {
      color: var(--sdesign-font-color-2);
      // 修改过的
      // margin-right: var(--sdesign-spacing-large);
    }
  }

  .@{ant-prefix}-form-item-label
    > label.@{ant-prefix}-form-item-required:not(.@{ant-prefix}-form-item-required-mark-optional)::before {
    width: 8px;
    height: 22px;
    line-height: 22px;
    margin-right: var(--sdesign-spacing-small);
  }

  &-horizontal {
    .@{ant-prefix}-divider-horizontal {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }
}

.@{ant-prefix}-form-vertical .@{ant-prefix}-form-item-label,
.@{ant-prefix}-col-24.@{ant-prefix}-form-item-label,
.@{ant-prefix}-col-xl-24.@{ant-prefix}-form-item-label {
  padding-bottom: var(--sdesign-spacing-basic);
}

.@{ant-prefix}-table {
  .@{ant-prefix}-form-item {
    margin-bottom: 0;
  }
}

.@{ant-prefix}-steps
  .@{ant-prefix}-steps-item-wait
  > .@{ant-prefix}-steps-item-container
  > .@{ant-prefix}-steps-item-content
  > .@{ant-prefix}-steps-item-title {
  font-weight: var(--sdesign-font-weight-regular);
}
