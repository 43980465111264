@breadcrumb-prefix-cls: ~'@{ant-prefix}-breadcrumb';

.@{breadcrumb-prefix-cls} {
  .reset-component();

  color: @breadcrumb-base-color;
  font-size: @breadcrumb-font-size;
  background-color: var(--sdesign-color-grey-2);
  padding: 13px 0 13px var(--sdesign-spacing-large);

  .@{iconfont-css-prefix} {
    font-size: @breadcrumb-icon-font-size;
  }

  ol {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    color: var(--sdesign-font-color-2);
  }

  a {
    transition: color 0.3s;
    font-size: var(--sdesign-font-size-regular);
    font-weight: var(--sdesign-font-weight-regular);
    color: var(--sdesign-font-color-2);

    &:hover {
      color: var(--sdesign-color-brand-5);
    }

    &:active {
      color: var(--sdesign-color-brand-7);
    }
  }

  li:last-child {
    color: var(--sdesign-font-color-1);
    font-size: var(--sdesign-font-size-regular);
    font-weight: var(--sdesign-font-weight-medium);

    a {
      color: @breadcrumb-last-item-color;
    }
  }

  li:last-child>&-separator {
    display: none;
  }

  &-separator {
    margin: 0 var(--sdesign-spacing-regular);
    color: @breadcrumb-separator-color;
  }

  &-link {

    >.@{iconfont-css-prefix}+span,
    >.@{iconfont-css-prefix}+a {
      margin-left: 4px;
    }
  }

  &-overlay-link {
    >.@{iconfont-css-prefix} {
      margin-left: 4px;
    }
  }
}

@import './rtl';