// sdesign tokens
@import (inline) './tokens.css';
// antd 全局less变量文件
@import '~antd/dist/antd.variable.less';

// custom
@import './utils.less';
@import './components/index.less';
@import './variable.less';

// hover-4  active-7  默认-5  outline-2
:root {
  --sdesign-primary-color: var(--sdesign-color-brand-6);
  --sdesign-primary-color-hover: var(--sdesign-color-brand-5);
  --sdesign-primary-color-active: var(--sdesign-color-brand-7);
  --sdesign-primary-bg-color: var(--sdesign-color-bg-3);
  // table color
  --sdesign-table-header-bg-color: var(--sdesign-color-fill-5); // table header底色
  --sdesign-table-header-text-color: var(--sdesign-font-color-2); // table header文本颜色
  --sdesign-table-body-zebra-color: var(--sdesign-color-fill-6); // table body 斑马纹颜色
  --sdesign-table-body-border-color: var(--sdesign-color-grey-3); // table body 边框颜色
  // 边框色
  --sdesign-border-color-base: var(--sdesign-color-border-default);
}

body {
  -webkit-font-smoothing: antialiased;
}
