// @import 'antd/es/style/themes/index';
// @import 'antd/es/style/mixins/index';

:root {
  .@{ant-prefix}-image-preview-switch-right {
    width: 80px;
    height: 80px;
    right: var(--sdesign-spacing-extra-huge) !important;
  }

  .@{ant-prefix}-image-preview-switch-left {
    width: 80px;
    height: 80px;
    left: var(--sdesign-spacing-extra-huge) !important;
  }

  .@{ant-prefix}-image-preview-switch-left>.anticon, .@{ant-prefix}-image-preview-switch-right>.anticon {
    font-size: 32px;
  }
  
  .@{ant-prefix}-image-preview-operations-operation {
    margin-left: 0,
  }
}
