// @import 'antd/es/style/themes/index';
// @import 'antd/es/style/mixins/index';

// @timeline-prefix-cls: ~'@{ant-prefix}-timeline';

.@{timeline-prefix-cls} {
    //   .reset-component();

    //   margin: 0;
    //   padding: 0;
    //   list-style: none;

    //   &-item {
    //     position: relative;
    //     margin: 0;
    //     padding-bottom: @timeline-item-padding-bottom;
    //     font-size: @font-size-base;
    //     list-style: none;

    //     &-tail {
    //       position: absolute;
    //       top: 10px;
    //       left: 3px;
    //       height: calc(100% - 10px);
    //       border-left: @timeline-width solid @timeline-color;
    //     }

    //     &-pending &-head {
    //       font-size: @font-size-sm;
    //       background-color: transparent;
    //     }

    //     &-pending &-tail {
    //       display: none;
    //     }

    //     &-head {
    //       position: absolute;
    //       width: 8px;
    //       height: 8px;
    //       background-color: @disabled-color;
    //       border-width: 0;
    //       border-radius: 100px;

    //       &-blue {
    //         background-color: @primary-color;
    //       }

    //       &-red {
    //         background-color: @error-color;
    //       }

    //       &-green {
    //         background-color: @success-color;
    //       }

    //       &-gray {
    //         background-color: @disabled-color;
    //       }
    //     }

    //     &-head-custom {
    //       position: absolute;
    //       top: 5.5px;
    //       left: 5px;
    //       width: auto;
    //       height: auto;
    //       margin-top: 0;
    //       padding: 3px 1px;
    //       line-height: 1;
    //       text-align: center;
    //       border: 0;
    //       border-radius: 0;
    //       transform: translate(-50%, -50%);
    //     }

    //     &-content {
    //       position: relative;
    //       top: -(@font-size-base * @line-height-base - @font-size-base) + 1px;
    //       margin: 0 0 0 var(--sdesign-spacing-large);
    //       word-break: break-word;
    //       font-size: var(--sdesign-font-size-regular);
    //       font-weight: var(--sdesign-font-weight-regular);
    //       color: var(--sdesign-font-color-1);
    //     }

    //     &-last {
    //       > .@{timeline-prefix-cls}-item-tail {
    //         display: none;
    //       }
    //       > .@{timeline-prefix-cls}-item-content {
    //         min-height: 48px;
    //       }
    //     }
    //   }

    //   &.@{timeline-prefix-cls}-alternate,
    //   &.@{timeline-prefix-cls}-right,
    //   &.@{timeline-prefix-cls}-label {
    //     .@{timeline-prefix-cls}-item {
    //       &-tail,
    //       &-head,
    //       &-head-custom {
    //         left: 50%;
    //       }

    //       &-head {
    //         margin-left: -4px;

    //         &-custom {
    //           margin-left: 1px;
    //         }
    //       }

    //       &-left {
    //         .@{timeline-prefix-cls}-item-content {
    //           left: calc(50% - 4px);
    //           width: calc(50% - 14px);
    //           text-align: left;
    //         }
    //       }

    //       &-right {
    //         .@{timeline-prefix-cls}-item-content {
    //           width: calc(50% - 12px);
    //           margin: 0;
    //           text-align: right;
    //         }
    //       }
    //     }
    //   }

    //   &.@{timeline-prefix-cls}-right {
    //     .@{timeline-prefix-cls}-item-right {
    //       .@{timeline-prefix-cls}-item-tail,
    //       .@{timeline-prefix-cls}-item-head,
    //       .@{timeline-prefix-cls}-item-head-custom {
    //         left: calc(100% - 4px - @timeline-width);
    //       }
    //       .@{timeline-prefix-cls}-item-content {
    //         width: calc(100% - 18px);
    //       }
    //     }
    //   }

    &&-pending &-item-last &-item-tail {
        height: calc(100% - 16px);
    }

    &-item-tail {
        top: 12px;
        height: calc(100% - 14px);
    }

    //   &&-reverse &-item-last &-item-tail {
    //     display: none;
    //   }

    //   &&-reverse &-item-pending {
    //     .@{timeline-prefix-cls}-item-tail {
    //       top: 15px;
    //       display: block;
    //       height: calc(100% - 15px);
    //       border-left: 2px dotted @timeline-color;
    //     }
    //     .@{timeline-prefix-cls}-item-content {
    //       min-height: 48px;
    //     }
    //   }
    //   &.@{timeline-prefix-cls}-label {
    //     .@{timeline-prefix-cls}-item-label {
    //       position: absolute;
    //       top: -(@font-size-base * @line-height-base - @font-size-base) + 1px;
    //       width: calc(50% - 12px);
    //       text-align: right;
    //     }
    //     .@{timeline-prefix-cls}-item-right {
    //       .@{timeline-prefix-cls}-item-label {
    //         left: calc(50% + 14px);
    //         width: calc(50% - 14px);
    //         text-align: left;
    //       }
    //     }
    //   }

    //   &.@{timeline-prefix-cls}-big {
    //     .@{timeline-prefix-cls}-item {
    //       &-tail {
    //         position: absolute;
    //         top: 19px;
    //         left: 8px;
    //         height: calc(100% - 19px);
    //         border-left: @timeline-width solid @timeline-color;
    //       }

    //       &-head {
    //         background-color: transparent;
    //         border: 5px solid @disabled-color;
    //         width: 19px;
    //         height: 19px;

    //         &-blue {
    //           border-color: @primary-color;
    //         }

    //         &-red {
    //           border-color: @error-color;
    //         }

    //         &-green {
    //           border-color: @success-color;
    //         }

    //         &-gray {
    //           border-color: @disabled-color;
    //         }
    //       }
    //       &-content {
    //         margin: 0 0 0 calc(var(--sdesign-spacing-huge) + 4px);
    //         font-size: var(--sdesign-font-size-regular);
    //         font-weight: var(--sdesign-font-weight-medium);
    //         color: var(--sdesign-font-color-1);
    //       }
    //     }
    //   }
}

// @import './rtl';
.@{ant-prefix}-timeline-item {
    &:not(.@{ant-prefix}-timeline-item-pending) {
        .@{ant-prefix}-timeline-item-head {
            transform: scale(0.8);
            background-color: var(--sdesign-color-brand-6);
            box-shadow: 0 2px 4px 0 rgba(51, 85, 255, 0.20);

        }
    }
}

// .@{ant-prefix}-timeline,.@{ant-prefix}-timeline-pending {
//   .@{ant-prefix}-timeline-item {
//     padding-bottom: 0px;
//     .@{ant-prefix}-timeline-item-tail {
//       height: var(--sdesign-spacing-medium);
//       border-left: 1px solid var(--sdesign-color-brand-6);
//       left: 3.4px;
//     }
//     .@{ant-prefix}-timeline-item-head {
//       width: 8px;
//       height: 8px;
//       background-color: var(--sdesign-color-brand-6);
//       top: 1px;
//     }
//     .@{ant-prefix}-timeline-item-content {
//       top: -8.001px;
//       margin: 0 0 0 var(--sdesign-spacing-large);
//       font-size: var(--sdesign-font-size-regular);
//       font-weight: var(--sdesign-font-weight-medium);
//       color: var(--sdesign-font-color-1);
//     }
//   }
//   .@{ant-prefix}-timeline-item-last {
//     .@{ant-prefix}-timeline-item-tail {
//       height: var(--sdesign-spacing-medium) !important;
//       border-left: 1px dotted var(--sdesign-color-grey-4) !important;
//     }
//     .@{ant-prefix}-timeline-item-content {
//       min-height: 0;
//     }
//   }
//   .@{ant-prefix}-timeline-item-pending {
//     .@{ant-prefix}-timeline-item-head-custom {
//       font-size: 8px;
//       background: #fff;
//       .@{ant-prefix}-timeline-item-head {
//         top: 0px;
//         left: -1.95px;
//         width: 8px;
//         height: 8px;
//       }
//       .@{ant-prefix}-timeline-item-head-gray {
//         background: var(--sdesign-color-grey-5);
//         border-color: var(--sdesign-color-grey-5);
//       }
//     }
//     .@{ant-prefix}-timeline-item-content {
//       color: var(--sdesign-font-color-3);
//       left: -1px;
//       margin: 0 0 0 var(--sdesign-spacing-large);
//       font-size: var(--sdesign-font-size-regular);
//       font-weight: var(--sdesign-font-weight-medium);
//       color: var(--sdesign-font-color-1);
//     }
//   }
// }

// .@{ant-prefix}-timeline-big {
//   .@{ant-prefix}-timeline-item {
//     padding-bottom: 24px;
//     .@{ant-prefix}-timeline-item-tail {
//       position: absolute;
//       top: 19px;
//       left: 9px;
//       height: var(--sdesign-spacing-huge);
//       border-left: 1px solid var(--sdesign-color-brand-6);
//     }
//     .@{ant-prefix}-timeline-item-head-custom {
//       left: 9px;
//     }
//     .@{ant-prefix}-timeline-item-head {
//       width: 20px;
//       height: 20px;
//       background-color: #fff;
//     }
//     .@{ant-prefix}-timeline-item-content {
//       top: -2px;
//       margin: 0 0 0 calc(var(--sdesign-spacing-huge) + 4px);
//       font-size: var(--sdesign-font-size-regular);
//       font-weight: var(--sdesign-font-weight-medium);
//       color: var(--sdesign-font-color-1);
//     }
//   }
//   .@{ant-prefix}-timeline-item-last {
//     .@{ant-prefix}-timeline-item-tail {
//       top: 25px;
//       height: var(--sdesign-spacing-huge) !important;
//       border-left: 1px dotted var(--sdesign-color-grey-4) !important;
//     }
//   }
//   .@{ant-prefix}-timeline-item-pending {
//     .@{ant-prefix}-timeline-item-head-custom {
//       font-size: 20px;
//       left: 11px;
//       .@{ant-prefix}-timeline-item-head-gray {
//         top: -6px;
//         width: 20px;
//         height: 20px;
//         background: #fff;
//       }
//     }
//     .@{ant-prefix}-timeline-item-head {
//       top: 19px;
//       background-color: #fff;
//     }
//     .@{ant-prefix}-timeline-item-content {
//       color: var(--sdesign-font-color-3);
//       top: 5px;
//       left: -1px;
//     }
//   }
// }