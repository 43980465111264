.@{ant-prefix}-tooltip-inner {
    padding: 6px calc(var(--sdesign-spacing-medium));
    font-size: var(--sdesign-font-size-extra-small);
    font-weight: var(--sdesign-font-weight-regular);
    color: var(--sdesign-color-white);
    // display: flex;
    // align-items: center;
}

.@{ant-prefix}-tooltip-arrow {
    width: 16px;
    height: 16px;
}

.@{ant-prefix}-tooltip-placement-top,
.@{ant-prefix}-tooltip-placement-topLeft,
.@{ant-prefix}-tooltip-placement-topRight {
    padding-bottom: 8px;
}

.@{ant-prefix}-tooltip-placement-right,
.@{ant-prefix}-tooltip-placement-rightTop,
.@{ant-prefix}-tooltip-placement-rightBottom {
    padding-left: 8px;
}

.@{ant-prefix}-tooltip-placement-bottom,
.@{ant-prefix}-tooltip-placement-bottomLeft,
.@{ant-prefix}-tooltip-placement-bottomRight {
    padding-top: 8px;
}

.@{ant-prefix}-tooltip-placement-left,
.@{ant-prefix}-tooltip-placement-leftTop,
.@{ant-prefix}-tooltip-placement-leftBottom {
    padding-right: 8px;
}