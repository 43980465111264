// .@{ant-prefix}-picker-time-panel{
//     width: auto;
// }
// .@{ant-prefix}-picker-time-panel-column{
//     width: 74px;
//     .@{ant-prefix}-picker-time-panel-cell{
//         transform: translateY(98px);

//         &-inner{
//             color: var(--sdesign-font-color-5);
            
//             &:hover{
//                 background: var(--sdesign-color-fill-hover);
//             }
//         }
//     }
// }

@time-panel-prefix: ~'@{ant-prefix}-picker-time-panel';

.@{time-panel-prefix}{
    width: auto;
   

    .@{time-panel-prefix}-column{
            width: 74px;

            &>li{

                &.@{time-panel-prefix}-cell{
                    transform: translateY(98px);
            
                    .@{time-panel-prefix}-cell-inner{
                        color: var(--sdesign-font-color-1);
                        
                        &:hover{
                            background: var(--sdesign-color-fill-hover);
                            color: var(--sdesign-color-brand-5);
                        }
                    }

                    &-selected{
                        .@{time-panel-prefix}-cell-inner{
                            color: var(--sdesign-color-brand-6);
                        }
                    }
                }
            }
        }
        

 
}