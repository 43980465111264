.@{ant-prefix}-checkbox {
  &-inner {
    border-width: 2px;
    border-radius: var(--sdesign-radius-medium);
  }

  // 半选
  &-indeterminate:not(&-disabled) {
    .@{ant-prefix}-checkbox-inner {
      border-color: var(--sdesign-primary-color);
      background-color: var(--sdesign-primary-color);

      &::after {
        height: 3px;
        border-radius: calc(var(--sdesign-radius-medium) - 1px);
        background-color: var(--sdesign-color-white);
        top: 50%;
        left: 50%;
      }

      &:hover {
        border-color: var(--sdesign-primary-color-hover);
        background-color: var(--sdesign-primary-color-hover);
      }

      &:active {
        border-color: var(--sdesign-primary-color-active);
        background-color: var(--sdesign-primary-color-active);
      }
    }
  }

  &-wrapper-disabled {
    .@{ant-prefix}-checkbox-indeterminate {
      .@{ant-prefix}-checkbox-inner {
        background-color: #D6DDFF;
        border-color: #D6DDFF !important;

        &::after {
          height: 3px;
          border-radius: calc(var(--sdesign-radius-medium) - 1px);
          background-color: var(--sdesign-color-white);
        }
      }
    }
    
    .@{ant-prefix}-checkbox-checked {
      .@{ant-prefix}-checkbox-inner {
        background-color: #D6DDFF;
        border-color: #D6DDFF !important;

        &::after {
          border-color: var(--sdesign-color-white);
        }
      }
    }

    .@{ant-prefix}-checkbox-inner {
      border-color: var(--sdesign-color-grey-3);
    }
  }
}

.@{ant-prefix}-checkbox-checked {
  .@{ant-prefix}-checkbox-inner {
    position: relative;

    &::after {
      top: 45%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%) rotate(40deg);
    }
  }
}
