// mixins for button
// ------------------------
@btn-primary-bg: var(--sdesign-color-brand-6);
@btn-danger-bg: var(--sdesign-color-red-6);
@error-bg-hover: var(--sdesign-color-red-1);
@error-bg-active: var(--sdesign-color-red-2);
@btn-default-color: var(--sdesign-color-grey-11);
@btn-default-border: var(--sdesign-color-grey-3);
@link-color: var(--sdesign-color-brand-6);
@btn-padding-horizontal-sm: var(--sdesign-spacing-regular);
@btn-padding-horizontal-base: var(--sdesign-spacing-medium);
@btn-padding-horizontal-lg: var(--sdesign-spacing-large);
@btn-disable-color: var(--sdesign-color-brand-3);
@btn-disable-border: var(--sdesign-color-brand-3);
@btn-disable-bg: #fff;

.button-color(@color; @background; @border) {
  color: @color;
  border-color: @border; // a inside Button which only work in Chrome

  & when not(@background =null) {
    background: @background;
  }

  // http://stackoverflow.com/a/17253457
  >a:only-child {
    color: currentcolor;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
      content: '';
    }
  }
}

.button-disabled(@color: @btn-disable-color; @background: @btn-disable-bg; @border: @btn-disable-border) {
  &[disabled] {

    &,
    &:hover,
    &:focus,
    &:active {
      .button-color(@color; @background; @border);

      text-shadow: none;
      box-shadow: none;
    }
  }
}

.button-variant-primary(@color; @background; @backgroundHover: @primary-color-hover; @backgroundActive: @primary-color-active) {
  .button-color(@color; @background; @background);

  text-shadow: @btn-text-shadow;
  box-shadow: none;

  &:focus {
    .button-color(@color; @background; @background);
  }

  &:hover {
    & when (@theme =dark) {
      .button-color(@color; ~`colorPalette('@{background}', 7) `; ~`colorPalette('@{background}', 7) `);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(@color; @backgroundHover; @backgroundHover);
    }

    & when (@theme =variable) {
      .button-color(@color; @backgroundHover; @backgroundHover);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(@color; ~`colorPalette('@{background}', 5) `; ~`colorPalette('@{background}', 5) `);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(@color; @backgroundActive; @backgroundActive);
    }

    & when (@theme =variable) {
      .button-color(@color; @backgroundActive; @backgroundActive);
    }
  }

  .button-disabled(#fff, var(--sdesign-color-brand-3), var(--sdesign-color-brand-3));
}

.button-variant-other(@color; @background; @border) {
  .button-color(@color; @background; @border);

  &:focus {
    .button-color(@color; @background; @border);
  }

  &:hover {
    & when (@theme =dark) {
      .button-color(@primary-5; @background; @primary-5);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(@primary-color-hover; @background; @primary-color-hover);
    }

    & when (@theme =variable) {
      .button-color(@primary-color-hover; @background; @primary-color-hover);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(@primary-7; @background; @primary-7);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(@primary-color-active; @background; @primary-color-active);
    }

    & when (@theme =variable) {
      .button-color(@primary-color-active; @background; @primary-color-active);
    }
  }

  .button-disabled();
}

.button-variant-ghost(@color; @border; @borderHover: yellow; @borderActive: yellow) {
  .button-color(@color; null; @border);
  text-shadow: none;

  &:focus {
    .button-color(@color; null; @border);
  }

  &:hover {
    & when (@border =transparent) {
      & when (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 7) `; null; transparent);
      }

      & when (not (@theme =dark) and not (@theme =variable)) {
        .button-color(@borderActive; var(--sdesign-color-brand-1); transparent);
      }

      & when (@theme =variable) {
        .button-color(@borderActive; var(--sdesign-color-brand-1); transparent);
      }
    }

    & when not (@border =transparent) {
      & when (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 7) `; null; ~`colorPalette('@{color}', 7) `);
      }

      & when (not (@theme =dark) and not (@theme =variable)) {
        .button-color(@borderHover; var(--sdesign-color-brand-1); @borderHover);
      }

      & when (@theme =variable) {
        .button-color(@borderHover; var(--sdesign-color-brand-1); @borderHover);
      }
    }
  }

  &:active {
    & when (@border =transparent) {
      & when (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 5) `; null; transparent);
      }

      & when (not (@theme =dark) and not (@theme =variable)) {
        .button-color(@borderActive; var(--sdesign-color-brand-2); transparent);
      }

      & when (@theme =variable) {
        .button-color(@borderActive; var(--sdesign-color-brand-2); transparent);
      }
    }

    & when not (@border =transparent) {
      & when (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 5) `; null; ~`colorPalette('@{color}', 5) `);
      }

      & when (not (@theme =dark) and not (@theme =variable)) {
        .button-color(@borderActive; var(--sdesign-color-brand-2); @borderActive);

      }

      & when (@theme =variable) {
        .button-color(@borderActive; var(--sdesign-color-brand-2); @borderActive);
      }
    }
  }

  .button-disabled(var(--sdesign-color-grey-4), transparent, transparent);
}

.btn-ghost-danger(@color; @border; @borderHover: yellow; @borderActive: yellow) {
  .button-color(@color; null; @border);
  text-shadow: none;

  &:focus {
    .button-color(@color; null; @border);
  }

  &:hover {
    .button-color(@borderHover; var(--sdesign-color-red-1); @borderHover);
  }

  &:active {
    .button-color(@borderActive; var(--sdesign-color-red-2); @borderActive);
  }

  .button-disabled(var(--sdesign-color-grey-4), transparent, transparent);
}

.button-group-base(@btnClassName) {
  position: relative;
  display: inline-flex;

  >.@{btnClassName},
  >span>.@{btnClassName} {
    position: relative;

    &:hover,
    &:focus,
    &:active {
      z-index: 2;
    }

    &[disabled] {
      z-index: 0;
    }
  }

  .@{btnClassName}-icon-only {
    font-size: @font-size-base;
  }
}

// Base styles of buttons
// --------------------------------------------------
.btn() {
  font-size: var(--sdesign-font-size-regular);
  font-weight: var(--sdesign-font-weight-regular);

  >.@{iconfont-css-prefix} {
    line-height: 1;
  }

  &,
  &:active,
  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &:not([disabled]):hover {
    text-decoration: none;
  }

  &:not([disabled]):active {
    outline: 0;
    box-shadow: none;
  }

  &[disabled] {
    cursor: not-allowed;

    >* {
      pointer-events: none;
    }
  }

  padding:4px var(--sdesign-spacing-medium);
  height:32px;

  &-lg {
    font-size: var(--sdesign-font-size-regular);
    padding: 4px var(--sdesign-spacing-medium);
    height: 36px;
  }

  &-sm {
    padding: 0 var(--sdesign-spacing-regular);
    font-size: var(--sdesign-font-size-small);
    height: 28px;
  }

  &.mini {
    padding: 0 var(--sdesign-spacing-regular);
    font-size: var(--sdesign-font-size-small);
    height: 24px;
  }
}

// primary button style
.btn-primary() {
  .button-variant-primary(@btn-primary-color; @btn-primary-bg; @primary-color-hover; @primary-color-active);
}

// default button style
.btn-default() {
  .button-variant-other(@btn-default-color; @btn-default-bg; @btn-default-border; );

  &:focus {
    .button-color(@btn-default-color; @btn-default-bg; @btn-default-border; );
  }

  &:hover {
    .button-color(@primary-color-hover; var(--sdesign-color-brand-1); var(--sdesign-color-brand-5));
  }

  &:active {
    .button-color(var(--sdesign-color-brand-7); var(--sdesign-color-brand-2); var(--sdesign-color-brand-7));
  }

  .button-disabled(var(--sdesign-color-grey-4), transparent, transparent);

}

// ghost button style
.btn-ghost() {
  .button-variant-other(@btn-ghost-color, @btn-ghost-bg, @btn-ghost-border);
}

// dashed button style
.btn-dashed() {
  &:focus {
    .button-color(@btn-default-color; @btn-default-bg; @btn-default-border; );
  }

  &:hover {
    .button-color(@primary-color-hover; var(--sdesign-color-brand-1); var(--sdesign-color-brand-5));
  }

  &:active {
    .button-color(var(--sdesign-color-brand-7); var(--sdesign-color-brand-2); var(--sdesign-color-brand-7));
  }

  .button-disabled(var(--sdesign-color-grey-4), transparent, transparent);
  border-style: dashed;
}

.btn-dashed() {
  &.primary {
    .button-color(var(--sdesign-color-brand-6); transparent; var(--sdesign-color-brand-6));

    &:hover {
      .button-color(@primary-color-hover; var(--sdesign-color-brand-1); var(--sdesign-color-brand-5));
    }

    &:active {
      .button-color(var(--sdesign-color-brand-7); var(--sdesign-color-brand-2); var(--sdesign-color-brand-7));
    }
  }
}


// danger button style
.btn-danger() {
  .button-variant-primary(@btn-danger-color, @btn-danger-bg, @error-color-hover, @error-color-active);
  .button-disabled(#fff, var(--sdesign-color-red-3), var(--sdesign-color-red-3));
}

// danger default button style
.btn-danger-default() {
  .button-color(var(--sdesign-color-red-6), @btn-default-bg, var(--sdesign-color-red-6));

  &:focus {
    .button-color(var(--sdesign-color-red-6), @btn-default-bg, var(--sdesign-color-red-6));
  }


  &:hover {
    & when (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 7) `; @btn-default-bg; ~`colorPalette('@{error-color}', 7) `);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(~`colorPalette('@{error-color}', 5) `; @btn-default-bg; ~`colorPalette('@{error-color}', 5) `);
    }

    & when (@theme =variable) {
      .button-color(@error-color-hover, @error-bg-hover, @error-color-hover);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 5) `; @btn-default-bg; ~`colorPalette('@{error-color}', 5) `);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(@error-color-active, @btn-default-bg, @error-color-active);

    }

    & when (@theme =variable) {
      .button-color(@error-color-active, @error-bg-active, @error-color-active);
    }
  }

  .button-disabled(var(--sdesign-color-grey-4), transparent, transparent);
}

// danger link button style
.btn-danger-link() {
  .button-variant-other(@error-color, transparent, transparent);
  box-shadow: none;

  &:hover,
  &:focus {
    & when (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 7) `; transparent; transparent);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(@error-color-hover; transparent; transparent);

    }

    & when (@theme =variable) {
      .button-color(@error-color-hover; transparent; transparent);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 5) `; transparent; transparent);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(@error-color-active; transparent; transparent);

    }

    & when (@theme =variable) {
      .button-color(@error-color-active; transparent; transparent);
    }
  }

  .button-disabled(@disabled-color; transparent; transparent);
}

// link button style
.btn-link() {
  .button-variant-other(@primary-color, transparent, transparent);
  box-shadow: none;

  &:focus {
    .button-color(@primary-color, transparent, transparent);
  }

  &:hover {
    color: @primary-color-hover;
    border-color: transparent;
  }

  &:active {
    color: var(--sdesign-color-brand-7);
    border-color: transparent;
  }

  .button-disabled(var(--sdesign-color-brand-3); transparent; transparent);
}

// link button disabled style
.btn-href-disabled() {
  cursor: not-allowed;

  >* {
    pointer-events: none;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    .button-color(@btn-disable-color, transparent, transparent);

    text-shadow: none;
    box-shadow: none;
  }
}

// text button style
.btn-text() {
  .button-variant-other(@primary-color, transparent, transparent);
  box-shadow: none;

  &:focus {
    .button-color(@primary-color, transparent, transparent);
  }

  &:hover {
    color: @primary-color-hover;
    background: var(--sdesign-color-brand-1);
    border-color: transparent;
  }

  &:active {
    color: var(--sdesign-color-brand-7);
    background: var(--sdesign-color-brand-2);
    border-color: transparent;
  }

  .button-disabled(var(--sdesign-color-brand-3); transparent; transparent);
}

.btn-fill() {
  .button-variant-other(@btn-default-color, var(--sdesign-color-fill-3), transparent);
  box-shadow: none;

  &:focus {
    .button-color(@btn-default-color, var(--sdesign-color-fill-3), transparent);
  }

  &:hover {
    color: @primary-color-hover;
    background: var(--sdesign-color-brand-1);
    border-color: transparent;
  }

  &:active {
    color: var(--sdesign-color-brand-7);
    background: var(--sdesign-color-brand-2);
    border-color: transparent;
  }

  .button-disabled(var(--sdesign-color-grey-4); var(--sdesign-color-fill-3); transparent);
}

.btn-danger-text() {
  .button-variant-other(@error-color, transparent, transparent);
  box-shadow: none;

  &:focus {
    .button-color(@error-color, transparent, transparent);
  }

  &:hover {
    & when (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 7) `; @btn-text-hover-bg; transparent);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(~`colorPalette('@{error-color}', 5) `; @btn-text-hover-bg; transparent);
    }

    & when (@theme =variable) {
      .button-color(@error-color-hover; @error-bg-hover; transparent);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 5) `; fadein(@btn-text-hover-bg, 1%); transparent);
    }

    & when (not (@theme =dark) and not (@theme =variable)) {
      .button-color(~`colorPalette('@{error-color}', 7) `; fadein(@btn-text-hover-bg, 1%); transparent);
    }

    & when (@theme =variable) {
      .button-color(@error-color-active; @error-bg-active; transparent);
    }
  }

  .button-disabled(var(--sdesign-color-red-3); transparent; transparent);
}

// round button
.btn-round(@btnClassName: btn) {
  font-size: var(--sdesign-font-size-regular);
  padding: 4px var(--sdesign-spacing-medium);
  height: 32px;

  &.@{btnClassName}-lg {
    font-size: var(--sdesign-font-size-regular);
    padding: 4px var(--sdesign-spacing-medium);
    height: 36px;

  }

  &.@{btnClassName}-sm {
    font-size: var(--sdesign-font-size-small);
    padding: 0 var(--sdesign-spacing-regular);
    height: 28px;
  }
}

// square button: the content only contains icon
.btn-square(@btnClassName: btn) {
  font-size: var(--sdesign-font-size-regular);
  height: 32px;

  &>* {
    font-size: var(--sdesign-font-size-medium);
  }

  &.@{btnClassName}-lg {
    &>* {
      font-size: var(--sdesign-font-size-medium);
    }
  }

  &.@{btnClassName}-sm {
    font-size: var(--sdesign-font-size-small);

    &>* {
      font-size: var(--sdesign-font-size-small);
    }
  }
}

// circle button: the content only contains icon
.btn-circle(@btnClassName: btn) {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;

  &.@{btnClassName}-lg {
    min-width: 36px;
    border-radius: 50%;
  }

  &.@{btnClassName}-sm {
    min-width: 28px;
    border-radius: 50%;
  }

  &.mini {
    min-width: 24px;
    border-radius: 50%;
  }
}

// Horizontal button groups style
// --------------------------------------------------
.btn-group(@btnClassName: btn) {
  .button-group-base(@btnClassName);

  &-sm {
    >.@{btnClassName}:only-child {
      border-radius: @btn-border-radius-sm;
    }

    >span:only-child>.@{btnClassName} {
      border-radius: @btn-border-radius-sm;
    }

    >.@{btnClassName}:first-child:not(:last-child),
    >span:first-child:not(:last-child)>.@{btnClassName} {
      border-top-left-radius: @btn-border-radius-sm;
      border-bottom-left-radius: @btn-border-radius-sm;
    }

    >.@{btnClassName}:last-child:not(:first-child),
    >span:last-child:not(:first-child)>.@{btnClassName} {
      border-top-right-radius: @btn-border-radius-sm;
      border-bottom-right-radius: @btn-border-radius-sm;
    }
  }

  &>& {
    float: left;
  }

  &>&:not(:first-child):not(:last-child)>.@{btnClassName} {
    border-radius: 0;
  }

  &>&:first-child:not(:last-child) {
    >.@{btnClassName}:last-child {
      padding-right: 8px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &>&:last-child:not(:first-child)>.@{btnClassName}:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}