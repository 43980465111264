.@{ant-prefix}-steps {
  &-item {
    &-title {
      margin-bottom: var(--sdesign-spacing-small);
      font-weight: 400;
    }
  }
}

.@{ant-prefix}-steps-small:not(.@{ant-prefix}-steps-dot) {
  .@{ant-prefix}-steps-item-icon {
    border-width: 2px;

    >.@{ant-prefix}-steps-icon {
      font-weight: 600;
      top: -1.5px
    }
  }
}

.@{ant-prefix}-steps-item-finish {
  &>.@{ant-prefix}-steps-item-container>.@{ant-prefix}-steps-item-content>.@{ant-prefix}-steps-item-title {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: var(--sdesign-color-grey-11);
    font-weight: 500;
  }

  &>.@{ant-prefix}-steps-item-container>.@{ant-prefix}-steps-item-content>.@{ant-prefix}-steps-item-description {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: var(--sdesign-color-grey-9);
    line-height: 18px;
    font-weight: 400;
  }

  .@{ant-prefix}-steps-item-icon {
    border-width: 2px;
  }
}

.@{ant-prefix}-steps-item-process {
  &>.@{ant-prefix}-steps-item-container>.@{ant-prefix}-steps-item-content>.@{ant-prefix}-steps-item-title {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: var(--sdesign-color-brand-6);
    font-weight: 500;

    &:after {
      background: #e0e0e0;
    }
  }

  &>.@{ant-prefix}-steps-item-container>.@{ant-prefix}-steps-item-content>.@{ant-prefix}-steps-item-description {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: var(--sdesign-color-grey-9);
    line-height: 18px;
    font-weight: 400;
  }
}

.@{ant-prefix}-steps-item-wait {
  &>.@{ant-prefix}-steps-item-container>.@{ant-prefix}-steps-item-content>.@{ant-prefix}-steps-item-title {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #b8b8b8;
    font-weight: 500;

    &:after {
      background: #e0e0e0;
    }
  }

  &>.@{ant-prefix}-steps-item-container>.@{ant-prefix}-steps-item-content>.@{ant-prefix}-steps-item-description {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #b8b8b8;
    line-height: 18px;
    font-weight: 400;
  }

  .@{ant-prefix}-steps-item-icon {
    border-color: #e0e0e0;
  }

  .@{ant-prefix}-steps-item-icon>.@{ant-prefix}-steps-icon {
    color: #b8b8b8;
  }
}

.@{ant-prefix}-steps-item-error {
  &>.@{ant-prefix}-steps-item-container>.@{ant-prefix}-steps-item-content>.@{ant-prefix}-steps-item-title {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #f23030;
    font-weight: 500;

    &:after {
      background: #e0e0e0;
    }
  }

  &>.@{ant-prefix}-steps-item-container>.@{ant-prefix}-steps-item-content>.@{ant-prefix}-steps-item-description {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: var(--sdesign-color-grey-9);
    line-height: 18px;
    font-weight: 400;
  }

  .@{ant-prefix}-steps-item-icon {
    border-width: 2px;
    border-color: #f23030;
  }
}

.@{ant-prefix}-steps-item-process {
  .@{ant-prefix}-steps-icon-dot {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 5px;
      background: #fff;
      content: "";
      border-radius: 50%;
      transform: translate(50%, 50%);
    }
  }
}

.@{ant-prefix}-steps-vertical {
  .@{ant-prefix}-steps-dot .@{ant-prefix}-steps-item-error .@{ant-prefix}-steps-item-icon {
    transform: translate(-50%, -50%);
  }

  >.@{ant-prefix}-steps-item .@{ant-prefix}-steps-item-icon {
    top: -2px;
  }
}

.@{ant-prefix}-steps-label-vertical .@{ant-prefix}-steps-item-error .@{ant-prefix}-steps-item-icon {
  margin-top: -2px;
  margin-left: 66px;
}

.@{ant-prefix}-steps-vertical.@{ant-prefix}-steps-dot .@{ant-prefix}-steps-item-process .@{ant-prefix}-steps-item-icon {
  margin-top: 9px;
  margin-left: -1px;
  margin-right: 13px;
}

.@{ant-prefix}-steps-vertical.@{ant-prefix}-steps-dot .@{ant-prefix}-steps-item-error .@{ant-prefix}-steps-item-icon {
  margin-top: 10px;
  margin-left: -2px;
  margin-right: 13px;
}

.@{ant-prefix}-steps-dot .@{ant-prefix}-steps-item-error .@{ant-prefix}-steps-item-icon,
.@{ant-prefix}-steps-dot.@{ant-prefix}-steps-small .@{ant-prefix}-steps-item-error .@{ant-prefix}-steps-item-icon {
  width: 10px;
  height: 10px;
  transform: scale(1.2);
  border-radius: 6px;
}

.@{ant-prefix}-steps-dot .@{ant-prefix}-steps-item-process .@{ant-prefix}-steps-item-icon,
.@{ant-prefix}-steps-dot.@{ant-prefix}-steps-small .@{ant-prefix}-steps-item-process .@{ant-prefix}-steps-item-icon {
  width: 10px;
  height: 10px;
  transform: scale(1.2);
  border-radius: 6px;

}



.@{ant-prefix}-steps-item-error {
  .@{ant-prefix}-steps-icon-dot {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 5px;
      background: #fff;
      content: "";
      border-radius: 50%;
      transform: translate(50%, 50%);
    }
  }
}

.@{ant-prefix}-small .@{ant-prefix}-item-custom .@{ant-prefix}-item-icon>.@{ant-prefix}-icon {
  font-size: 22px;
  line-height: 22px;
}

.@{ant-prefix}-steps-finish-icon {
   background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="7.8" viewBox="0 0 10.5 7.8"%3E%3Cpath d="M3.75,7.8105L0,4.0605L1.0605,3L3.75,5.6895L9.4395,0L10.5,1.0605L3.75,7.8105Z" fill="%233355ff" fill-opacity="1" ></path></svg>');
  background-size: 12px 12px;
  width: 12px;
  height: 12px;


  svg {
    display: none;
  }
}
