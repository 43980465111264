@backtop-prefix-cls: ~'@{ant-prefix}-back-top';
@back-top-color: var(--sdesign-color-grey-11);
@back-top-bg: var(--sdesign-color-white);
@back-top-hover-bg: var(--sdesign-color-white);
@sdesign-backtop-hover-color: var(--sdesign-color-brand-6);
@sdesign-backtop-active-color: var(--sdesign-color-white);
@sdesign-backtop-active-bg-color: var(--sdesign-color-brand-6);

.@{backtop-prefix-cls} {
    position: fixed;
    &-content {
        width: 40px;
        height: 40px;
        overflow: hidden;
        color: @back-top-color;
        text-align: center;
        background-color: @back-top-bg;
        border-radius: var(--sdesign-radius-medium);
        transition: all 0.3s;
        border: 1px solid #e0e0e0;
        box-shadow: var(--sdesign-shadow-1);

        &:hover {
            background-color: @back-top-hover-bg;
            transition: all 0.3s;
            border-color: @sdesign-backtop-hover-color;
            color: @sdesign-backtop-hover-color;
        }
        
        &:active {
            border-color: @sdesign-backtop-active-bg-color;
            background-color: @sdesign-backtop-active-bg-color;
            color: @sdesign-backtop-active-color;
        }
    }

    &-icon {
        font-size: 24px;
        line-height: 40px;
        span {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTAyNHB4IiBoZWlnaHQ9IjEwMjRweCIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT7nvJbnu4QgMjwvdGl0bGU+CiAgICA8ZyBpZD0iLS0tLS0t5Zue5Yiw6aG26YOoIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0i57yW57uELTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIxNC4wODAwMDAsIDI5Ny45MjAwMDApIiBmaWxsPSIjMzMzMzMzIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQ0NS42OTYsNDk5LjQ1NiBMNTI4Ljg5Niw0OTkuNDU2IEw1MjguODk2LC0xMi41NDQgTDQ0NS42OTYsLTEyLjU0NCBMNDQ1LjY5Niw0OTkuNDU2IFogTTQxLjkyLDQ3MC4wMTYgTDEwMC43MzYsNTI4Ljg5NiBMMzg2LjE3NiwyNDMuNDU2IEwxMDAuNzM2LC00MS45MiBMNDEuOTIsMTYuODk2IEwyNjguNTQ0LDI0My40NTYgTDQxLjkyLDQ3MC4wMTYgWiIgaWQ9IkZpbGwtMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjg1LjQwODAwMCwgMjQzLjQ4ODAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMjg1LjQwODAwMCwgLTI0My40ODgwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
            min-width: 24px;
            height: 24px;
            background-size: 24px;
            svg {
                display: none;
            }
        }
        &:hover{
            span {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTAyNHB4IiBoZWlnaHQ9IjEwMjRweCIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT7nvJbnu4QgMjwvdGl0bGU+CiAgICA8ZyBpZD0iLS0tLS0t5Zue5Yiw6aG26YOoIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0i57yW57uELTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIxNC4wODAwMDAsIDI5Ny45MjAwMDApIiBmaWxsPSIjMzM1NUZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQ0NS42OTYsNDk5LjQ1NiBMNTI4Ljg5Niw0OTkuNDU2IEw1MjguODk2LC0xMi41NDQgTDQ0NS42OTYsLTEyLjU0NCBMNDQ1LjY5Niw0OTkuNDU2IFogTTQxLjkyLDQ3MC4wMTYgTDEwMC43MzYsNTI4Ljg5NiBMMzg2LjE3NiwyNDMuNDU2IEwxMDAuNzM2LC00MS45MiBMNDEuOTIsMTYuODk2IEwyNjguNTQ0LDI0My40NTYgTDQxLjkyLDQ3MC4wMTYgWiIgaWQ9IkZpbGwtMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjg1LjQwODAwMCwgMjQzLjQ4ODAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMjg1LjQwODAwMCwgLTI0My40ODgwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
            }
        }
        &:active{
            span {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTAyNHB4IiBoZWlnaHQ9IjEwMjRweCIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT7nvJbnu4QgMjwvdGl0bGU+CiAgICA8ZyBpZD0iLS0tLS0t5Zue5Yiw6aG26YOoIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0i57yW57uELTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIxNC4wODAwMDAsIDI5Ny45MjAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQ0NS42OTYsNDk5LjQ1NiBMNTI4Ljg5Niw0OTkuNDU2IEw1MjguODk2LC0xMi41NDQgTDQ0NS42OTYsLTEyLjU0NCBMNDQ1LjY5Niw0OTkuNDU2IFogTTQxLjkyLDQ3MC4wMTYgTDEwMC43MzYsNTI4Ljg5NiBMMzg2LjE3NiwyNDMuNDU2IEwxMDAuNzM2LC00MS45MiBMNDEuOTIsMTYuODk2IEwyNjguNTQ0LDI0My40NTYgTDQxLjkyLDQ3MC4wMTYgWiIgaWQ9IkZpbGwtMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjg1LjQwODAwMCwgMjQzLjQ4ODAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMjg1LjQwODAwMCwgLTI0My40ODgwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
            }
        }
    }
}