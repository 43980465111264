@sdesign-radius-medium: var(--sdesign-radius-medium);
@sdesign-color-brand-6: var(--sdesign-color-brand-6);
@sdesign-color-white: var(--sdesign-color-white);
@sdesign-color-black: var(--sdesign-color-black);
@sdesign-line-height: 32px;
@sdesign-block-height: 32px;
@sdesign-block-width: 32px;
@sdesign-normal-border: 1px solid #e0e0e0;
@sdesign-small-font-size: 8px;

.@{ant-prefix}-pagination {
  &-item {
    background: @sdesign-color-white;
    border: @sdesign-normal-border;
    border-radius: @sdesign-radius-medium;
    margin-right: var(--sdesign-spacing-basic);

    &:hover {
      background: var(--sdesign-color-brand-1);
      border-color: var(--sdesign-color-brand-5);
      color: var(--sdesign-color-brand-5);

      a {
        color: var(--sdesign-color-brand-5);
      }
    }

    &:active {
      background: var(--sdesign-color-brand-2);
      border-color: var(--sdesign-color-brand-7);
      color: var(--sdesign-color-brand-7);

      a {
        color: var(--sdesign-color-brand-7);
      }
    }
  }

  &-total-text {
    line-height: @pagination-item-size;
  }

  .@{ant-prefix}-pagination {
    &-next,
    &-prev {
      margin-right: var(--sdesign-spacing-basic);

      &:not(.@{ant-prefix}-pagination-disabled) {
        &:hover {
          .@{ant-prefix}-pagination-item-link {
            color: var(--sdesign-color-brand-5);
            border-color: var(--sdesign-color-brand-5);
            background: var(--sdesign-color-brand-1);
          }
        }

        &:active {
          .@{ant-prefix}-pagination-item-link {
            color: var(--sdesign-color-brand-7);
            border-color: var(--sdesign-color-brand-7);
            background: var(--sdesign-color-brand-2);
          }
        }
      }

      .@{ant-prefix}-pagination-item-link {
        height: @sdesign-block-height;
        border: @sdesign-normal-border;
      }
    }
  }

  &-jump-next,
  &-jump-prev {
    margin-right: var(--sdesign-spacing-basic);
    background: @sdesign-color-white;
    border: @sdesign-normal-border;
    border-radius: @sdesign-radius-medium;

    .@{ant-prefix}-pagination-item-container {
      .@{ant-prefix}-pagination-item-ellipsis {
        color: @sdesign-color-black;
        font-size: @sdesign-small-font-size;
      }
    }

    &:hover {
      border-color: var(--sdesign-color-brand-5);
      background: var(--sdesign-color-brand-1);

      .@{ant-prefix}-pagination-item-container {
        .@{ant-prefix}-pagination-item-link-icon {
          color: var(--sdesign-color-brand-5);
        }
      }
    }

    &:active {
      border-color: var(--sdesign-color-brand-7);
      background: var(--sdesign-color-brand-2);

      .@{ant-prefix}-pagination-item-container {
        .@{ant-prefix}-pagination-item-link-icon {
          color: var(--sdesign-color-brand-7);
        }
      }
    }
  }

  &-item-active,
  &-item-active:hover {
    background: @sdesign-color-brand-6;
    border-radius: @sdesign-radius-medium;
    border-color: @sdesign-color-brand-6;

    a {
      color: @sdesign-color-white;
    }
  }

  &-options {
    margin-left: var(--sdesign-spacing-basic);
  }

  // .mini兼容3升4的包
  &.@{ant-prefix}-pagination-mini,
  &.mini {
    line-height: @sdesign-line-height;

    .@{ant-prefix}-pagination-jump-next,
    .@{ant-prefix}-pagination-jump-prev {
      border: 0;
      border-radius: 0;

      .@{ant-prefix}-pagination-item-container {
        .@{ant-prefix}-pagination-item-ellipsis {
          color: @sdesign-color-black;
          font-size: @sdesign-small-font-size;
        }
      }
    }

    .@{ant-prefix}-pagination-options-quick-jumper {
      input {
        height: @sdesign-block-height;
      }
    }

    .@{ant-prefix}-pagination-item {
      height: @sdesign-block-height;
      line-height: @sdesign-line-height;
      min-width: @sdesign-block-width;
      border-radius: @sdesign-radius-medium;
      margin-right: var(--sdesign-spacing-basic);

      &:hover {
        color: var(--sdesign-color-brand-5);
      }

      &:active {
        color: var(--sdesign-color-brand-7);
      }
    }

    .@{ant-prefix}-pagination-item-active {
      min-width: @sdesign-block-width;
    }

    .@{ant-prefix}-pagination-prev,
    .@{ant-prefix}-pagination-next {
      height: calc(@sdesign-block-height - 2px);
      line-height: 1;

      .@{ant-prefix}-pagination-item-link {
        border: none;

        &:hover,
        &:active {
          background: transparent;
          border-color: transparent;
        }
      }

      &:not(.@{ant-prefix}-pagination-disabled) {
        &:hover {
          .@{ant-prefix}-pagination-item-link {
            background: none;
          }
        }

        &:active {
          .@{ant-prefix}-pagination-item-link {
            background: none;
          }
        }
      }
    }

    .@{ant-prefix}-pagination-jump-next,
    .@{ant-prefix}-pagination-jump-prev {
      &:hover,
      &:active {
        background: transparent;
      }
    }

    .@{ant-prefix}-select-sm {
      &.@{ant-prefix}-select-single {
        .@{ant-prefix}-select-selector {
          height: 32px;
          padding: 0 9px;

          .@{ant-prefix}-select-selection-item {
            line-height: 30px;
            padding-right: 24px;
          }
        }
      }
    }
  }
}

.@{ant-prefix}-pagination-simple {
  line-height: @sdesign-line-height;

  .@{ant-prefix}-pagination-simple-pager {
    input {
      height: @sdesign-block-height;
    }
  }

  .@{ant-prefix}-pagination-prev,
  .@{ant-prefix}-pagination-next {
    height: @sdesign-block-height;
    line-height: @sdesign-line-height;

    &:not(.@{ant-prefix}-pagination-disabled) {
      &:hover {
        .@{ant-prefix}-pagination-item-link {
          background: none;
        }
      }

      &:active {
        .@{ant-prefix}-pagination-item-link {
          background: none;
        }
      }
    }

    .@{ant-prefix}-pagination-item-link {
      border: none;
    }
  }
}
