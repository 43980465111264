@tree-select-prefix-cls: ~'@{ant-prefix}-select-tree';

.@{tree-select-prefix-cls}-checkbox {
    height: 100%;
    margin: auto 0 auto var(--sdesign-spacing-small);

    &::after {
        border-radius: var(--sdesign-radius-medium);
    }

    &-inner {
        border-radius: var(--sdesign-radius-medium);
        height: 14px;
        width: 14px;
    }
}

.@{tree-select-prefix-cls}-checkbox-indeterminate {
    .@{tree-select-prefix-cls}-checkbox-inner {
        background-color: var(--sdesign-color-brand-6);
        border-color: var(--sdesign-color-brand-6) !important;

        &::after {
            height: 2px;
            background-color: var(--sdesign-color-white);
        }
    }
}

.@{tree-select-prefix-cls}-checkbox-checked {
    .@{tree-select-prefix-cls}-checkbox {
        &-inner {
            &::after {
                transform: rotate(45deg) scale(1) translate(-75%, -50%);
            }
        }
    }
}