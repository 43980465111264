@import './checkbox.less';

@tree-select-prefix-cls: ~'@{ant-prefix}-tree';
@sdesign-line-height: 32px;
@sdesign-block-height: 32px;

.setLineHeightAndHeight() {
    line-height: @sdesign-block-height;
    height: @sdesign-line-height;
}

.@{tree-select-prefix-cls} {

    .@{tree-select-prefix-cls}-treenode {
        * {
            font-size: var(--sdesign-font-size-regular);
            font-weight: var(--sdesign-font-weight-regular);
            color: var(--sdesign-font-color-1);
        }

        width: 100%;
        cursor: pointer;
        padding: 0 var(--sdesign-spacing-regular);
        background-color: var(--sdesign-color-fill-1);
        margin-bottom: var(--sdesign-spacing-small);
        align-items: center;

        &:not(&-disabled) {
            &.@{tree-select-prefix-cls}-treenode-selected {
                &:hover {
                    .@{tree-select-prefix-cls}-title {
                        font-weight: var(--sdesign-font-weight-medium);
                    }
                }
            }

            &:hover {
                background-color: var(--sdesign-color-fill-hover);

                .@{tree-select-prefix-cls}-title {
                    color: var(--sdesign-color-brand-5);
                    font-weight: var(--sdesign-font-weight-regular);
                }

                /*多选下的悬浮设置， 仅适用于谷歌浏览105版本以上*/
                &:has(.@{ant-prefix}-tree-checkbox) .@{tree-select-prefix-cls}-title {
                    color: var(--sdesign-font-color-1) !important;
                }
            }
        }

        &-disabled {
            .@{tree-select-prefix-cls}-title {
                color: var(--sdesign-font-color-5);
            }

            svg {
                fill: var(--sdesign-font-color-5);
            }

            &:hover {
                background-color: var(--sdesign-color-fill-hover);

                .@{tree-select-prefix-cls}-title {
                    color: var(--sdesign-font-color-5);
                }

                svg {
                    fill: var(--sdesign-font-color-5);
                }
            }

            &:has(.@{ant-prefix}-tree-checkbox) {
                svg {
                    fill: var(--sdesign-font-color-1) !important;
                }

                .@{tree-select-prefix-cls}-title {
                    color: var(--sdesign-font-color-1) !important;
                }
            }
        }

    }

    .@{tree-select-prefix-cls}-treenode-selected {
        &.@{tree-select-prefix-cls}-treenode-checkbox-checked {
            .@{tree-select-prefix-cls}-title {
                color: var(--sdesign-font-color-1);
                font-weight: var(--sdesign-font-weight-regular);
            }
        }

        .@{tree-select-prefix-cls}-title {
            font-size: var(--sdesign-font-size-regular);
            font-weight: var(--sdesign-font-weight-medium);
            color: var(--sdesign-color-brand-6);
        }
    }

    .@{tree-select-prefix-cls}-node-content-wrapper {
        flex: 1;

        &.@{tree-select-prefix-cls}-node-selected {
            background-color: initial;
        }

        &:hover {
            background-color: initial;
        }

        .@{tree-select-prefix-cls}-icon__customize {
            margin-right: 0;
            padding: 0 var(--sdesign-spacing-small);
            .setLineHeightAndHeight();

        }
    }

    .@{tree-select-prefix-cls}-switcher {
        line-height: 32px;
        width: auto;
        margin-left: 10px;

        .@{tree-select-prefix-cls}-switcher-icon {
            vertical-align: -2px
        }
    }

    .@{tree-select-prefix-cls}-node-content-wrapper {
        .setLineHeightAndHeight();
    }

    &-switcher-noop {
        display: none;
    }
}