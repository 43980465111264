@picker-cell-inner-cls: ~'@{picker-prefix-cls}-cell-inner';
@picker-basic-cell-hover-with-range-color: var(--sdesign-color-brand-1);

.@{picker-prefix-cls} {
  @picker-panel-cell-width: 42px;
  @picker-panel-header-height: 22px;
  @picker-arrow-size: 7px;
  @picker-year-month-cell-width: 60px;
  // @picker-panel-width: @picker-panel-cell-width * 7 + @padding-sm * 2 + 4;
  @picker-panel-width: @picker-panel-cell-width * 7 + @padding-sm * 2 + 4;
  @picker-week-panel-width: @picker-panel-cell-width * 8 + @padding-sm * 2 + 4;
  @border-radius: 4px;

  .ant-btn-primary() {
    border: none;
    border: 4px;
    font-size: 12px;
    line-height: 28px;
    height: 28px;
    padding: 0 12px;
  }

  &-panel {
    // display: inline-flex;
    // flex-direction: column;
    // text-align: center;
    // background: @calendar-bg;
    // border: @border-width-base @border-style-base @picker-border-color;
    // border-radius: @border-radius-base;
    // outline: none;


    &-focused {
      // border-color: @primary-color;
    }


  }

  // ========================================================
  // =                     Shared Panel                     =
  // ========================================================
  // &-decade-panel,
  // &-year-panel,
  // &-quarter-panel,
  // &-month-panel,
  &-date-panel,
  &-time-panel {
    // display: flex;
    // flex-direction: column;
    width: @picker-panel-width;
  }

  &-week-panel {
    width: @picker-week-panel-width;
  }

  // ======================= Header =======================
  &-header {
    margin: 0 var(--sdesign-spacing-large);
    padding: 0;
    border: none;
    padding: var(--sdesign-spacing-medium) 0;
    // display: flex;
    // padding: 0 @padding-xs;
    // color: @heading-color;
    // border-bottom: @border-width-base @border-style-base @picker-border-color;

    >* {
      // flex: none;
    }

    button {
      line-height: @picker-panel-header-height;
      // padding: 0;
      // color: @disabled-color;
      // line-height: @picker-text-height;
      // background: transparent;
      // border: 0;
      // cursor: pointer;
      // transition: color @animation-duration-slow;
    }

    >button {
      min-width: 16px;
      margin-right: var(--sdesign-spacing-regular);

      &:last-child {
        margin-right: 0;
      }

      // font-size: @font-size-base;

      &:hover {
        // color: @text-color;
      }
    }

    &-view {
      // flex: auto;
      // font-weight: 500;
      line-height: @picker-panel-header-height;

      button {
        // color: inherit;
        // font-weight: inherit;

        &:not(:first-child) {
          // margin-left: @padding-xs;
        }

        &:hover {
          // color: @primary-color;
        }
      }
    }
  }

  // Arrow button
  &-prev-icon,
  &-next-icon,
  &-super-prev-icon,
  &-super-next-icon {
    // position: relative;
    // display: inline-block;
    // width: @picker-arrow-size;
    // height: @picker-arrow-size;

    &::before {
      // position: absolute;
      // top: 0;
      // left: 0;
      // display: inline-block;
      // width: @picker-arrow-size;
      // height: @picker-arrow-size;
      // border: 0 solid currentcolor;
      // border-width: 1.5px 0 0 1.5px;
      // content: '';
    }
  }

  &-super-prev-icon,
  &-super-next-icon {
    &::after {
      // position: absolute;
      // top: ceil((@picker-arrow-size / 2));
      // left: ceil((@picker-arrow-size / 2));
      // display: inline-block;
      // width: @picker-arrow-size;
      // height: @picker-arrow-size;
      // border: 0 solid currentcolor;
      // border-width: 1.5px 0 0 1.5px;
      // content: '';
    }
  }

  &-prev-icon,
  &-super-prev-icon {
    // transform: rotate(-45deg);
  }

  &-next-icon,
  &-super-next-icon {
    // transform: rotate(135deg);
  }

  // ======================== Body ========================
  &-content {
    // width: 277px;
    margin: 0 auto;

    // width: 100%;
    // table-layout: fixed;
    // border-collapse: collapse;
    thead {
      border-bottom: 1px solid rgba(224, 224, 224, 0.60);

      th {
        height: @picker-panel-header-height;
        padding-bottom: var(--sdesign-spacing-medium);
        line-height: @picker-panel-header-height;

        &[aria-label="empty cell"] {
          display: none;
        }
      }
    }

    tbody {
      tr {

        // 顶部星期间隔样式调整
        &:first-child {
          td {
            padding-top: 16px;

            &::before {
              margin-top: 5px;
            }

            &.@{picker-prefix-cls}-cell-in-view {

              &.@{picker-prefix-cls}-cell-range-hover::after,
              &.@{picker-prefix-cls}-cell-range-hover-start::after,
              &.@{picker-prefix-cls}-cell-range-hover-end::after {
                margin-top: 5px;
              }
            }

          }
        }
      }
    }

    th,
    td {}

  }

  .picker-cell-inner(@cellClassName) {


    // >>> Default
    .@{cellClassName} {
      // position: relative;
      // z-index: 2;
      // display: inline-block;
      // min-width: @picker-panel-cell-height;
      // height: @picker-panel-cell-height;
      // line-height: @picker-panel-cell-height;
      // border-radius: @border-radius-base;
      // transition: background @animation-duration-slow, border @animation-duration-slow;
    }

    // >>> Hover
    &:hover:not(&-in-view),
    &:hover:not(&-selected):not(&-range-start):not(&-range-end):not(&-range-hover-start):not(&-range-hover-end) {
      .@{cellClassName} {
        // background: @picker-basic-cell-hover-color;
      }
    }

    // >>> Today
    &-in-view&-today .@{cellClassName} {
      &::before {
        border: none; //????
        position: absolute;
        width: var(--sdesign-spacing-extra-small);
        height: var(--sdesign-spacing-extra-small);
        margin-left: 0;
        // background: var(--sdesign-color-brand-6);
        top: auto;
        right: auto;
        bottom: 1px;
        left: 50%;
        z-index: 1;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        content: '';
      }
    }

    // >>> In Range
    &-in-view&-in-range {
      // position: relative;

      &::before {
        // background: @picker-basic-cell-active-with-range-color;
      }
    }

    // >>> Selected
    &-in-view&-selected .@{cellClassName},
    &-in-view&-range-start .@{cellClassName},
    &-in-view&-range-end .@{cellClassName} {
      // color: @text-color-inverse;
      // background: var(--sdesign-color-brand-6);
    }

    &-in-view&-range-start:not(&-range-start-single),
    &-in-view&-range-end:not(&-range-end-single) {
      &::before {

        // background: @picker-basic-cell-active-with-range-color;
      }
    }

    &-in-view&-range-start::before {
      // left: 50%;
    }

    &-in-view&-range-end::before {
      // right: 50%;
    }

    // >>> Range Hover
    &-in-view&-range-hover-start:not(&-in-range):not(&-range-start):not(&-range-end),
    &-in-view&-range-hover-end:not(&-in-range):not(&-range-start):not(&-range-end),
    &-in-view&-range-hover-start&-range-start-single,
    &-in-view&-range-hover-start&-range-start&-range-end&-range-end-near-hover,
    &-in-view&-range-hover-end&-range-start&-range-end&-range-start-near-hover,
    &-in-view&-range-hover-end&-range-end-single,
    &-in-view&-range-hover:not(&-in-range) {
      &::after {
        height: 30px;
        border: none;
        background: var(--sdesign-color-brand-1);
        // position: absolute;
        // top: 50%;
        // z-index: 0;
        // height: 24px;
        // border-top: @border-width-base dashed @picker-date-hover-range-border-color;
        // border-bottom: @border-width-base dashed @picker-date-hover-range-border-color;
        // transform: translateY(-50%);
        // transition: all @animation-duration-slow;
        // content: '';
      }
    }

    // Add space for stash
    &-range-hover-start::after,
    &-range-hover-end::after,
    &-range-hover::after {
      height: 30px;

      // right: 0;
      left: 0px;
    }

    // Hover with in range
    &-in-view&-in-range&-range-hover::before,
    &-in-view&-range-start&-range-hover::before,
    &-in-view&-range-end&-range-hover::before,
    &-in-view&-range-start:not(&-range-start-single)&-range-hover-start::before,
    &-in-view&-range-end:not(&-range-end-single)&-range-hover-end::before,
    .@{picker-prefix-cls}-panel> :not(.@{picker-prefix-cls}-date-panel) &-in-view&-in-range&-range-hover-start::before,
    .@{picker-prefix-cls}-panel> :not(.@{picker-prefix-cls}-date-panel) &-in-view&-in-range&-range-hover-end::before {
      height: 30px;

      // background: @picker-date-hover-range-color;
    }

    // range start border-radius
    &-in-view&-range-start:not(&-range-start-single):not(&-range-end) .@{cellClassName} {
      // border-radius: @border-radius-base 0 0 @border-radius-base;
    }

    // range end border-radius
    &-in-view&-range-end:not(&-range-end-single):not(&-range-start) .@{cellClassName} {
      // border-radius: 0 @border-radius-base @border-radius-base 0;
    }

    // DatePanel only
    .@{picker-prefix-cls}-date-panel &-in-view&-in-range&-range-hover-start .@{cellClassName},
    .@{picker-prefix-cls}-date-panel &-in-view&-in-range&-range-hover-end .@{cellClassName} {
      &::after {
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // z-index: -1;
        // background: @picker-date-hover-range-color;
        // transition: all @animation-duration-slow;
        // content: '';
      }
    }

    .@{picker-prefix-cls}-date-panel &-in-view&-in-range&-range-hover-start .@{cellClassName}::after {
      // right: -5px - @border-width-base;
      // left: 0;
    }

    .@{picker-prefix-cls}-date-panel &-in-view&-in-range&-range-hover-end .@{cellClassName}::after {
      // right: 0;
      // left: -5px - @border-width-base;
    }

    // Hover with range start & end
    &-range-hover&-range-start::after {
      // right: 50%;
    }

    &-range-hover&-range-end::after {
      // left: 50%;
    }

    // Edge start
    tr>&-in-view&-range-hover:first-child::after,
    tr>&-in-view&-range-hover-end:first-child::after,
    &-in-view&-start&-range-hover-edge-start&-range-hover-edge-start-near-range::after,
    &-in-view&-range-hover-edge-start:not(&-range-hover-edge-start-near-range)::after,
    &-in-view&-range-hover-start::after {
      border: none;
      background: var(--sdesign-color-brand-1);
      // left: 6px;
      // border-left: @border-width-base dashed @picker-date-hover-range-border-color;
      // border-top-left-radius: @border-radius-base;
      // border-bottom-left-radius: @border-radius-base;
    }

    // Edge end
    tr>&-in-view&-range-hover:last-child::after,
    tr>&-in-view&-range-hover-start:last-child::after,
    &-in-view&-end&-range-hover-edge-end&-range-hover-edge-end-near-range::after,
    &-in-view&-range-hover-edge-end:not(&-range-hover-edge-end-near-range)::after,
    &-in-view&-range-hover-end::after {
      border: none;
      background: var(--sdesign-color-brand-1);
      // right: 6px;
      // border-right: @border-width-base dashed @picker-date-hover-range-border-color;
      // border-top-right-radius: @border-radius-base;
      // border-bottom-right-radius: @border-radius-base;
    }

    // // 自定义样式
    // &-in-view&-end{
    //   &::before{
    //     border-top-right-radius: 4px;
    //     border-bottom-right-radius: 4px;
    //   }
    // }


    &-in-view&-range-hover-start::after {
      border-top-left-radius: var(--sdesign-spacing-large);
      border-bottom-left-radius: var(--sdesign-spacing-large);
    }

    &-in-view&-range-hover-end::after {
      border-top-right-radius: var(--sdesign-spacing-large);
      border-bottom-right-radius: var(--sdesign-spacing-large);
    }

    // >>> Disabled
    &-disabled {
      // color: @disabled-color;
      // pointer-events: none;


      .@{cellClassName} {
        // background: transparent;
      }



    }

    &-disabled&-today .@{cellClassName}::before {
      // border-color: @disabled-color;
    }
  }

  &-cell {
    width: 50px;
    padding: 6px 2px;

    // padding: 3px 0;
    // color: @disabled-color;
    // cursor: pointer;
    // &:first-child {
    //   &::before {
    //     border-top-left-radius: 4px;
    //     border-bottom-left-radius: 4px;
    //   }
    // }
    // &:last-child {
    //   &::before {
    //     border-top-right-radius: 4px;
    //     border-bottom-right-radius: 4px;
    //   }
    // }
    &-in-range {

      // 鼠标hove虚线效果圆角
      &:first-child {
        &::before {
          border-top-left-radius: var(--sdesign-radius-medium);
          border-bottom-left-radius: var(--sdesign-radius-medium);
        }
      }

      &:last-child {
        &::before {
          height: 30px;
          border-top-right-radius: var(--sdesign-radius-medium);
          border-bottom-right-radius: var(--sdesign-radius-medium);
        }
      }
    }

    &-start {
      &::before {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &-end {
      &::before {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }


    &::before {
      height: 30px;
    }

    // In view
    &-inner {
      border-radius: var(--sdesign-spacing-large) !important;
      height: 30px !important;
      width: 30px;
      line-height: 30px !important;

      &:hover {
        background: var(--sdesign-color-fill-hover);
        // background:#EBF1FF;
      }
    }

    &-in-view {
      color: var(--sdesign-font-color-1);

      &:hover {
        color: var(--sdesign-color-brand-5);
      }

    }


    .picker-cell-inner(~'@{picker-cell-inner-cls}');
  }

  &-decade-panel,
  &-year-panel,
  &-quarter-panel,
  &-month-panel {
    .@{picker-prefix-cls}-content {
      // height: @picker-panel-without-time-cell-height * 4;
    }

    .@{picker-cell-inner-cls} {
      // padding: 0 @padding-xs;
    }
  }

  &-quarter-panel {
    .@{picker-prefix-cls}-content {
      // height: 56px;
    }
  }

  // ======================== Footer ========================
  &-footer {

    // width: min-content;
    // min-width: 100%;
    // line-height: @picker-text-height - 2 * @border-width-base;
    // text-align: center;
    // border-bottom: @border-width-base @border-style-base transparent;
    .@{ant-prefix}-btn-primary {
      .ant-btn-primary()
    }

    .@{picker-prefix-cls}-panel & {
      // border-top: @border-width-base @border-style-base @picker-border-color;
    }

    &-extra {
      // padding: 0 @padding-sm;
      // line-height: @picker-text-height - 2 * @border-width-base;
      // text-align: left;

      &:not(:last-child) {
        // border-bottom: @border-width-base @border-style-base @picker-border-color;
      }
    }

    .@{picker-prefix-cls}-ranges {
      padding: var(--sdesign-spacing-small) var(--sdesign-spacing-large);
    }
  }

  &-now {
    // text-align: left;
  }

  &-today-btn {
    // color: var(--sdesign-color-brand-6);

    &:hover {
      // color: @link-hover-color;
    }

    &:active {
      // color: @link-active-color;
    }

    &&-disabled {
      // color: @disabled-color;
      // cursor: not-allowed;
    }
  }

  // ========================================================
  // =                       Special                        =
  // ========================================================

  // ===================== Decade Panel =====================
  &-decade-panel {
    .@{picker-cell-inner-cls} {
      // padding: 0 (@padding-xs / 2);
    }

    .@{picker-prefix-cls}-cell::before {
      // display: none;
    }
  }

  // ============= Year & Quarter & Month Panel =============
  &-year-panel,
  &-quarter-panel,
  &-month-panel {
    @hover-cell-fixed-distance: ((((@picker-panel-width - @padding-xs * 2) / 3) - @picker-year-month-cell-width) / 2);

    .@{picker-prefix-cls}-body {

      // padding-top: 0px;
      // padding: 0 @padding-xs;
      padding: var(--sdesign-spacing-large) var(--sdesign-spacing-large);
      padding-top: 0;
    }

    .@{picker-cell-inner-cls} {
      // width: @picker-year-month-cell-width;
    }

    .@{picker-prefix-cls}-cell-range-hover-start::after {
      border: none;
      background: var(--sdesign-color-brand-1);

      // left: @hover-cell-fixed-distance;
      // border-left: @border-width-base dashed @picker-date-hover-range-border-color;
      // border-radius: @border-radius-base 0 0 @border-radius-base;

      .@{picker-prefix-cls}-panel-rtl & {
        border: none;
        background: var(--sdesign-color-brand-1);
        // right: @hover-cell-fixed-distance;
        // border-right: @border-width-base dashed @picker-date-hover-range-border-color;
        // border-radius: 0 @border-radius-base @border-radius-base 0;
      }
    }

    .@{picker-prefix-cls}-cell-range-hover-end::after {
      border: none;
      background: var(--sdesign-color-brand-1);
      // right: @hover-cell-fixed-distance;
      // border-right: @border-width-base dashed @picker-date-hover-range-border-color;
      // border-radius: 0 @border-radius-base @border-radius-base 0;

      .@{picker-prefix-cls}-panel-rtl & {
        border: none;
        background: var(--sdesign-color-brand-1);
        // left: @hover-cell-fixed-distance;
        // border-left: @border-width-base dashed @picker-date-hover-range-border-color;
        // border-radius: @border-radius-base 0 0 @border-radius-base;
      }
    }
  }

  // ====================== Week Panel ======================
  &-week-panel {

    .@{picker-prefix-cls}-body {

      // padding-top: 0px;
      // padding: @padding-xs @padding-sm;
      padding: var(--sdesign-spacing-large) var(--sdesign-spacing-large);
      padding-top: 0;
    }

    // Clear cell style
    .@{picker-prefix-cls}-cell {

      &:hover .@{picker-cell-inner-cls},
      &-selected .@{picker-cell-inner-cls},
      .@{picker-cell-inner-cls} {
        // background-color: var(--sdesign-color-brand-6);
        // background: transparent !important;

      }
    }

    &-row {
      td {

        // transition: background @animation-duration-slow;
        &.@{picker-prefix-cls}-cell-week {
          display: none;
        }
      }

      &:hover td {
        // color: var(--sdesign-color-brand-6);
        // background: @picker-basic-cell-hover-color;
      }

      &:hover {
        &:not(.@{ant-prefix}-picker-week-panel-row-selected) {
          border-radius: var(--sdesign-spacing-large);

          td {
            background: none;
            color: var(--sdesign-color-brand-5);

            &::before {
              background: var(--sdesign-color-brand-1);
            }



            &:nth-child(2) {
              &::before {
                border-top-left-radius: var(--sdesign-spacing-large);
                border-bottom-left-radius: var(--sdesign-spacing-large);
              }
            }

            &:last-child {
              border-top-right-radius: var(--sdesign-spacing-large);
              border-bottom-right-radius: var(--sdesign-spacing-large);

              &::before {
                border-top-right-radius: var(--sdesign-spacing-large);
                border-bottom-right-radius: var(--sdesign-spacing-large);
              }
            }
          }
        }
      }

      &-selected td {
        background: none;

        &::before {
          background: var(--sdesign-color-brand-6);
        }

        &:first-child {
          color: var(--sdesign-color-brand-6);

          &::before {
            z-index: -1;
          }

          &.@{ant-prefix}-picker-cell-week {
            // color: var(--sdesign-color-brand-6);
          }

        }

        &:nth-child(2) {
          &::before {
            border-top-left-radius: var(--sdesign-spacing-large);
            border-bottom-left-radius: var(--sdesign-spacing-large);
          }
        }

        &:last-child {
          &::before {
            border-top-right-radius: var(--sdesign-spacing-large);
            border-bottom-right-radius: var(--sdesign-spacing-large);
          }
        }
      }

      &-selected td,
      &-selected:hover td {
        background: none;

        &.@{picker-prefix-cls}-cell-week {
          color: var(--sdesign-color-brand-6);
          // color: fade(@text-color-inverse, 50%);
        }

        &.@{picker-prefix-cls}-cell-today .@{picker-cell-inner-cls}::before {
          // border-color: @text-color-inverse;
        }

        &.@{picker-prefix-cls}-cell-today {
          .@{picker-prefix-cls}-cell-inner {
            &::before {
              // border: none;
              // top: 90%;
              // left: 50%;
              // transform: translate(-50%, -50%);
              // width: 4px;
              // height: 4px;
              // border-radius: 2px;
              background-color: #f42c2c;
            }
          }
        }

        .@{picker-cell-inner-cls} {
          // color: @text-color-inverse;
        }
      }
    }
  }

  // ====================== Date Panel ======================
  &-date-panel {
    .@{picker-prefix-cls}-body {
      padding: var(--sdesign-spacing-large) var(--sdesign-spacing-large);
      padding-top: 0;

    }

    .@{picker-prefix-cls}-content {
      width: @picker-panel-cell-width * 7 - 12;

      th {
        width: @picker-panel-cell-width;
      }
    }
  }

  // ==================== Datetime Panel ====================
  &-datetime-panel {
    display: flex;

    .@{picker-prefix-cls}-time-panel {
      width: 250px;

      .@{picker-prefix-cls}-header {
        border-bottom: 1px solid rgba(224, 224, 224, 0.60);
      }

      .@{picker-prefix-cls}-content {
        width: 218px;

        .@{picker-prefix-cls}-time-panel-column {
          &>li {
            &.@{ant-prefix}-picker-time-panel-cell {
              transform: translateY(142px);
            }
          }
        }
      }




      // border-left: @border-width-base @border-style-base @picker-border-color;
    }

    .@{picker-prefix-cls}-date-panel,
    .@{picker-prefix-cls}-time-panel {
      // transition: opacity @animation-duration-slow;
    }

    // Keyboard
    &-active {

      .@{picker-prefix-cls}-date-panel,
      .@{picker-prefix-cls}-time-panel {
        // opacity: 0.3;

        &-active {
          // opacity: 1;
        }
      }
    }
  }

  // ====================== Time Panel ======================
  &-time-panel {
    // width: auto;
    // min-width: auto;

    .@{picker-prefix-cls}-content {
      // display: flex;
      // flex: auto;
      // height: @picker-time-panel-column-height;
    }

    &-column {
      // flex: 1 0 auto;
      // width: @picker-time-panel-column-width;
      // margin: 0;
      // padding: 0;
      // overflow-y: hidden;
      // text-align: left;
      // list-style: none;
      // transition: background @animation-duration-slow;

      &::after {
        // display: block;
        // height: @picker-time-panel-column-height - @picker-time-panel-cell-height;
        content: '';

        .@{picker-prefix-cls}-datetime-panel & {
          // height: @picker-time-panel-column-height - @picker-time-panel-cell-height + 2 *
          //   @border-width-base;
        }
      }

      &:not(:first-child) {
        border: none;
        // border-left: @border-width-base @border-style-base @picker-border-color;
      }

      &-active {
        // background: @calendar-column-active-bg;
      }

      &:hover {
        // overflow-y: auto;
      }

      >li {
        // margin: 0;
        // padding: 0;

        &.@{picker-prefix-cls}-time-panel-cell {
          .@{picker-prefix-cls}-time-panel-cell-inner {
            padding-left: 28px;
            border-radius: var(--sdesign-radius-medium);
            margin: 0 2px;
            width: auto;
            // text-align: center;
            // display: block;
            // width: 100%;
            // height: @picker-time-panel-cell-height;
            // margin: 0;
            // padding: 0 0 0 ((@picker-time-panel-column-width - 28px) / 2);
            // color: @text-color;
            // line-height: @picker-time-panel-cell-height;
            // border-radius: 0;
            // cursor: pointer;
            // transition: background @animation-duration-slow;

            &:hover {
              // background: @item-hover-bg;
            }
          }

          &-selected {
            .@{picker-prefix-cls}-time-panel-cell-inner {
              color: var(--sdesign-color-brand-6);
              // background: @calendar-item-active-bg;
            }
          }

          &-disabled {
            .@{picker-prefix-cls}-time-panel-cell-inner {
              // color: @disabled-color;
              // background: transparent;
              // cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

// Fix IE11 render bug by css hacks
// https://github.com/ant-design/ant-design/issues/21559
// https://codepen.io/afc163-1472555193/pen/mdJRaNj?editors=0110
/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen,
:root {
  .@{picker-prefix-cls}-range-wrapper {

    .@{picker-prefix-cls}-month-panel .@{picker-prefix-cls}-cell,
    .@{picker-prefix-cls}-year-panel .@{picker-prefix-cls}-cell {
      // padding: 21px 0;
    }
  }
}