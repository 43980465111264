@collapse-prefix-cls: ~'@{ant-prefix}-collapse';
@sdesign-collapse-header-height: 40px;

.@{collapse-prefix-cls} {
  background-color: var(--sdesign-color-white);
  padding: 0 var(--sdesign-spacing-huge);
  border-radius: var(--sdesign-radius-large);
  border: 1px solid var(--sdesign-color-border-hover);

  &-borderless {
    border: 0;
  }

  .@{collapse-prefix-cls} {
    border: none;
    background-color: var(--sdesign-color-fill-2);
  }

  &>&-item {
    padding: var(--sdesign-spacing-large) 0 0;

    &:last-child {
      border-bottom: none;
    }

    >.@{collapse-prefix-cls}-header {
      padding: 0 0 var(--sdesign-spacing-large);
      font-size: var(--sdesign-font-size-medium);
      font-weight: var(--sdesign-font-weight-medium);
      height: auto;
      line-height: 22px;

      .@{collapse-prefix-cls}-expand-icon {
        width: 16px;
        margin-right: var(--sdesign-spacing-large);
      }

      .@{collapse-prefix-cls}-arrow {
        vertical-align: 0;
      }
    }
  }

  &-content {
    background-color: transparent;
    border-top: none;

    &>&-box {
      padding: 0 34px;
      padding-bottom: var(--sdesign-spacing-large);

      p {
        margin: 0;
      }
    }
  }

  &>&-item-active {
    >.@{collapse-prefix-cls}-header .@{collapse-prefix-cls}-arrow.anticon-arrow-right {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}