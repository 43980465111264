// @import '../../style/themes/index';
// @import '../../style/mixins/index';
@import './size';
// @import './rtl';
@import './position';
// @import './dropdown';
@import './card';

@tab-prefix-cls: ~'@{ant-prefix}-tabs';

.@{tab-prefix-cls} {
  .reset-component();
  color: var(--sdesign-color-grey-9);
  display: flex;

  // ========================== Navigation ==========================
  >.@{tab-prefix-cls}-nav,
  >div>.@{tab-prefix-cls}-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    padding: 0;

    .@{tab-prefix-cls}-nav-wrap {
      position: relative;
      display: inline-block;
      display: flex;
      flex: auto;
      align-self: stretch;
      overflow: hidden;
      white-space: nowrap;
      height: 42px;
      transform: translate(0); // Fix chrome render bug

      // >>>>> Ping shadow
      &::before,
      &::after {
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: opacity @animation-duration-slow;
        content: '';
        pointer-events: none;
      }
    }

    .@{tab-prefix-cls}-nav-list {
      position: relative;
      display: flex;
      transition: transform @animation-duration-slow;
    }

    // >>>>>>>> Operations
    .@{tab-prefix-cls}-nav-operations {
      display: flex;
      align-self: stretch;

      &-hidden {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
      }
    }

    .@{tab-prefix-cls}-nav-more {
      position: relative;
      padding: @tabs-card-horizontal-padding;
      background: transparent;
      border: 0;
      width: max-content;
      cursor: pointer;

      .anticon {
        transition: all .3s;
      }

      &:hover {
        .anticon {
          transform: rotate(-180deg);
        }
      }

      &::before {
        // 修改过，直接去掉
        display: none;
        content: '更多';
        margin-right: 4px;
      }

      &::after {
        // 修改过，直接去掉
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 5px;
        transform: translateY(100%);
        content: '';
      }
    }

    .@{tab-prefix-cls}-nav-add {
      min-width: @tabs-card-height;
      margin-left: @tabs-card-gutter;
      padding: 0 @padding-xs;
      background: @tabs-card-head-background;
      border: @border-width-base @border-style-base @border-color-split;
      border-radius: @border-radius-base @border-radius-base 0 0;
      outline: none;
      cursor: pointer;
      transition: all @animation-duration-slow @ease-in-out;

      &:hover {
        color: var(--sdesign-color-grey-11);
      }

      &:active,
      &:focus {
        color: var(--sdesign-color-grey-11);
        font-weight: var(--sdesign-font-weight-medium);
      }
    }
  }

  &-extra-content {
    flex: none;

    .sdesign-remove-all {
      display: flex;
      align-items: flex-end;

      &__icon {
        width: 16px;
        height: 16px;
        background-size: 16px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBmaWxsPSJub25lIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBkPSJNMTMsMTQuOTk5OUw0LDE0Ljk5OTlDMy40NDc3MiwxNC45OTk5LDMsMTQuNTUyMiwzLDEzLjk5OTlMMywxMEwyLDEwQzEuNDQ3NzIsMTAsMSw5LjU1MjI4LDEsOUwxLDNDMSwyLjQ0NzcyLDEuNDQ3NzIsMiwyLDJMOCwyQzguNTUyMjgsMiw5LDIuNDQ3NzIsOSwzTDksMy45OTk4OEwxMywzLjk5OTg4QzEzLjU1MjMsMy45OTk4OCwxNCw0LjQ0NzU5LDE0LDQuOTk5ODhMMTQsMTMuOTk5OUMxNCwxNC41NTIyLDEzLjU1MjMsMTQuOTk5OSwxMywxNC45OTk5Wk0yLDNMMiw5TDMsOUwzLDQuOTk5ODhDMyw0LjQ0NzU5LDMuNDQ3NzIsMy45OTk4OCw0LDMuOTk5ODhMOCwzLjk5OTg4TDgsM0wyLDNaTTQsMTMuOTk5OUwxMywxMy45OTk5TDEzLDQuOTk5ODhMNCw0Ljk5OTg4TDQsMTMuOTk5OVpNMTEuMzY0Niw3LjM3ODU2TDkuMjQzMzQsOS40OTk5Mzk5OTk5OTk5OTlMMTEuNDE0MSwxMS42NzA3OUwxMC43MDcwOCwxMi4zNzc4TDguNTM2MjEsMTAuMjA2OThMNi4zNjQ2MywxMi4zNzg2TDUuNjU3NTMsMTEuNjcxNDVMNy44MjkwOCw5LjQ5OTlMNS43MDcwNyw3LjM3Nzg5TDYuNDE0MTgsNi42NzA3OEw4LjUzNjE5MDAwMDAwMDAwMSw4Ljc5Mjc5TDEwLjY1NzUxLDYuNjcxNDdMMTEuMzY0Niw3LjM3ODU2WiIgZmlsbD0iIzFBMUExQSIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvZz48L3N2Zz4=");
        cursor: pointer;
      }
    }
  }

  &-centered {

    >.@{tab-prefix-cls}-nav,
    >div>.@{tab-prefix-cls}-nav {
      .@{tab-prefix-cls}-nav-wrap {
        &:not([class*='@{tab-prefix-cls}-nav-wrap-ping']) {
          justify-content: center;
        }
      }
    }
  }

  // ============================ InkBar ============================
  &-ink-bar {
    position: absolute;
    background: @tabs-ink-bar-color;
    pointer-events: none;
  }

  // ============================= Tabs =============================
  &-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: @tabs-horizontal-padding;
    font-size: @tabs-title-font-size;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    &-btn,
    &-remove {

      &:focus,
      &:active {
        color: var(--sdesign-color-brand-7);
      }
    }

    &:hover &-remove {
      border-radius: 4px;
      display: block;
    }

    &-btn {
      outline: none;
      transition: all 0.3s;
      // 限制12个字
      max-width: 184px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-remove {
      flex: none;
      margin-left: var(--sdesign-spacing-basic);
      margin-right: 0;
      padding: 0;
      color: @text-color-secondary;
      font-size: @font-size-sm;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all @animation-duration-slow;
      width: 16px;
      height: 16px;
      line-height: 16px;

      .anticon {
        vertical-align: middle;
        width: 10px;
        height: 10px;
      }

      &:hover {
        background-color: var(--sdesign-color-fill-hover);
        color: var(--sdesign-color-link-brand-hover);
        border-radius: 4px;
      }

      &:active {
        background-color: var(--sdesign-color-brand-2);
        color: var(--sdesign-color-link-brand-active);
      }
    }

    // 激活时候的关闭按钮样式
    &-active &-remove {
      color: var(--sdesign-font-color-2);
      border-radius: 4px;
      display: block !important;
    }

    &:hover {
      color: var(--sdesign-color-brand-5);
      font-weight: var(--sdesign-font-weight-regular);
    }

    &&-active &-btn {
      font-weight: var(--sdesign-font-weight-medium);
      text-shadow: 0 0 0.25px currentcolor;

      .@{ant-prefix}-badge {
        color: var(--sdesign-color-grey-11)
      }
    }

    &&-disabled {
      color: @disabled-color;
      cursor: not-allowed;
    }

    &&-disabled &-btn,
    &&-disabled &-remove {

      &:focus,
      &:active {
        color: @disabled-color;
      }
    }

    & &-remove .@{iconfont-css-prefix} {
      margin: 0;
    }

    .@{iconfont-css-prefix} {
      margin-right: @margin-sm;
    }

    .@{ant-prefix}-badge {
      color: currentColor
    }
  }

  &-tab+&-tab {
    margin: @tabs-horizontal-margin;
  }

  // =========================== TabPanes ===========================
  &-content {
    &-holder {
      flex: auto;
      min-width: 0;
      min-height: 0;
    }

    position: relative;
    width: 100%;

    // 修改过
    &-left {
      height: 100%;
      overflow-y: auto;
    }
  }

  &-tabpane {
    outline: none;

    &-hidden {
      display: none;
    }
  }

  // Motion
  &-switch {

    &-appear,
    &-enter {
      transition: none;

      &-start {
        opacity: 0;
      }

      &-active {
        opacity: 1;
        transition: opacity @animation-duration-slow;
      }
    }

    &-leave {
      position: absolute;
      transition: none;
      inset: 0;

      &-start {
        opacity: 1;
      }

      &-active {
        opacity: 0;
        transition: opacity @animation-duration-slow;
      }
    }
  }
}

// 按钮样式
.buttonTabs.@{tab-prefix-cls} {

  .@{tab-prefix-cls}-nav::before,
  .@{tab-prefix-cls}-nav::before,
  div>.@{tab-prefix-cls}-nav::before,
  div>.@{tab-prefix-cls}-nav::before {
    border: none;
  }

  .@{tab-prefix-cls}-ink-bar.@{tab-prefix-cls}-ink-bar-animated {
    display: none;
  }

  .@{tab-prefix-cls}-tab {
    padding: 0px var(--sdesign-spacing-regular);
    font-size: var(--sdesign-font-size-regular);
    line-height: 32px;
  }

  .@{tab-prefix-cls}-tab+.@{tab-prefix-cls}-tab {
    margin-left: var(--sdesign-spacing-small);
  }

  &.@{tab-prefix-cls}-small {
    .@{tab-prefix-cls}-tab {
      padding: 0px var(--sdesign-spacing-medium);
      font-size: var(--sdesign-font-size-extra-small);
      line-height: 24px;
    }

    .@{tab-prefix-cls}-nav-wrap {
      padding: var(--sdesign-spacing-small);
    }
  }

  &.@{tab-prefix-cls}-large {
    .@{tab-prefix-cls}-tab+.@{tab-prefix-cls}-tab {
      margin-left: var(--sdesign-spacing-regular);
    }

    .@{tab-prefix-cls}-nav-wrap {
      padding: var(--sdesign-spacing-regular);
    }
  }

  .@{tab-prefix-cls}-nav-wrap {
    height: initial;
  }

  .@{tab-prefix-cls}-nav {
    margin: 0;
  }

  .@{tab-prefix-cls}-nav-wrap {
    padding: var(--sdesign-spacing-small);
    height: initial !important;
    background-color: var(--sdesign-color-white);
  }

  .@{tab-prefix-cls}-tab.@{tab-prefix-cls}-tab-active {
    background-color: var(--sdesign-color-brand-1);
    border-radius: 4px;

    .@{tab-prefix-cls}-tab-btn {
      color: var(--sdesign-color-brand-6);
      font-weight: var(--sdesign-font-weight-medium);
    }
  }
}

// 胶囊样式
.capsuleTabs.@{tab-prefix-cls} {

  .@{tab-prefix-cls}-nav::before,
  .@{tab-prefix-cls}-nav::before,
  div>.@{tab-prefix-cls}-nav::before,
  div>.@{tab-prefix-cls}-nav::before {
    border: none;
  }

  .@{tab-prefix-cls}-ink-bar.@{tab-prefix-cls}-ink-bar-animated {
    display: none;
  }

  .@{tab-prefix-cls}-tab {
    padding: 0px var(--sdesign-spacing-regular);
    font-size: var(--sdesign-font-size-regular);
    line-height: 32px;
  }

  .@{tab-prefix-cls}-tab+.@{tab-prefix-cls}-tab {
    margin-left: var(--sdesign-spacing-small);
  }

  &.@{tab-prefix-cls}-small {
    .@{tab-prefix-cls}-tab {
      padding: 0px var(--sdesign-spacing-medium);
      font-size: var(--sdesign-font-size-extra-small);
      line-height: 24px;
    }

    .@{tab-prefix-cls}-nav-wrap {
      padding: var(--sdesign-spacing-small);
    }
  }

  &.@{tab-prefix-cls}-large {
    .@{tab-prefix-cls}-tab+.@{tab-prefix-cls}-tab {
      margin-left: var(--sdesign-spacing-regular);
    }

    .@{tab-prefix-cls}-nav-wrap {
      padding: var(--sdesign-spacing-regular);
    }
  }

  .@{tab-prefix-cls}-nav {
    margin: 0;
  }

  .@{tab-prefix-cls}-nav-wrap {
    padding: var(--sdesign-spacing-small);
    height: initial !important;

    background-color: #f5f5f5;
  }

  .@{tab-prefix-cls}-tab.@{tab-prefix-cls}-tab-active {
    background-color: var(--sdesign-color-white);
    border-radius: 4px;

    .@{tab-prefix-cls}-tab-btn {
      color: var(--sdesign-color-brand-6);
      font-weight: var(--sdesign-font-weight-medium);
    }
  }
}

.noGapLine.@{tab-prefix-cls} {

  .@{tab-prefix-cls}-nav::before,
  .@{tab-prefix-cls}-nav::before,
  div>.@{tab-prefix-cls}-nav::before,
  div>.@{tab-prefix-cls}-nav::before {
    border: none;
  }
}

.badgeHighTabs.@{tab-prefix-cls} {
  .@{tab-prefix-cls}-tab-btn {
    overflow: visible;
  }
}

.badgeMediumTabs.@{tab-prefix-cls} {
  .@{tab-prefix-cls}-tab-btn {
    overflow: visible;
  }

  .@{ant-prefix}-badge-count {
    position: initial;
    background-color: transparent;
    color: var(--sdesign-color-brand-6);
    font-size: inherit;
    font-weight: inherit;
  }

  .@{ant-prefix}-badge-count::before {
    content: '(';
    display: inline;
  }

  .@{ant-prefix}-badge-count::after {
    content: ')';
    display: inline;
  }
}

.badgeLowTabs.@{tab-prefix-cls} {
  .@{tab-prefix-cls}-tab-btn {
    overflow: visible;
  }

  .@{ant-prefix}-badge-count {
    position: initial;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  .@{ant-prefix}-badge-count::before {
    content: '(';
    display: inline;
  }

  .@{ant-prefix}-badge-count::after {
    content: ')';
    display: inline;
  }
}

.@{tab-prefix-cls}-dropdown {
  padding: var(--sdesign-spacing-small);
}

.@{tab-prefix-cls}-dropdown,
.@{tab-prefix-cls}-dropdown-menu {
  .@{tab-prefix-cls}-dropdown-menu {
    border-radius: 8px;
    padding: 0;
  }

  .@{tab-prefix-cls}-dropdown-menu-item.@{tab-prefix-cls}-dropdown-menu-item-active,
  .@{tab-prefix-cls}-dropdown-menu-item {
    &:hover {
      background-color: var(--sdesign-color-brand-1);
      color: var(--sdesign-color-brand-5);
    }

    &:active {
      background-color: var(--sdesign-color-brand-2);
      color: var(--sdesign-color-brand-6);
    }

  }

  .@{tab-prefix-cls}-dropdown-menu-item.@{tab-prefix-cls}-dropdown-menu-item-disabled:hover {
    background-color: #fff;
    color: #b8b8b8;
  }

  .@{tab-prefix-cls}-dropdown-menu-submenu-title,
  .@{tab-prefix-cls}-dropdown-menu-item {
    font-size: var(--sdesign-font-size-regular);
    font-weight: var(--sdesign-font-weight-regular);
    margin: var(--sdesign-spacing-basic);
    border-radius: var(--sdesign-radius-medium);

    .@{tab-prefix-cls}-dropdown-menu-submenu-title {
      transition: all 0.1s;
    }
  }

  .@{tab-prefix-cls}-dropdown-menu-item-selected {
    background-color: var(--sdesign-color-brand-2);
    color: var(--sdesign-color-brand-6);
  }
}
