// @import 'antd/es/style/themes/index';
// @import 'antd/es/style/mixins/index';
@import './mixin';
@import './affix';
@import './allow-clear';
@import './status';
@input-prefix-cls: ~'@{ant-prefix}-input';

// Input styles
.@{input-prefix-cls} {
  .reset-component();
  .input();

  //== Style for input-group: input with label, with button or dropdown...
  &-group {
    .reset-component();
    .input-group(~'@{input-prefix-cls}');

    &-wrapper {
      display: inline-block;
      width: 100%;
      text-align: start;
      vertical-align: top; // https://github.com/ant-design/ant-design/issues/6403
    }
  }

  &-password-icon.@{iconfont-css-prefix} {
    color: @text-color-secondary;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: @input-icon-hover-color;
    }
  }

  &[type='color'] {
    height: @input-height-base;

    &.@{input-prefix-cls}-lg {
      height: @input-height-lg;
    }
    &.@{input-prefix-cls}-sm {
      height: @input-height-sm;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  &-textarea-show-count {
    // https://github.com/ant-design/ant-design/issues/33049
    > .@{input-prefix-cls} {
      height: 100%;
    }
    position: relative;

    &::after {
      position: absolute;
      right: var(--sdesign-spacing-regular);
      bottom: var(--sdesign-spacing-small);
      color: var(--sdesign-font-color-3);
      white-space: nowrap;
      content: attr(data-count);
      pointer-events: none;
      font-size: var(--sdesign-font-size-extra-small);
    }

    &.@{input-prefix-cls}-textarea-in-form-item {
      &::after {
        margin-bottom: -22px;
      }
    }
    
    textarea {
      padding: var(--sdesign-spacing-small) var(--sdesign-spacing-medium)
        var(--sdesign-spacing-huge);
    }
  }

  &-textarea-suffix {
    position: absolute;
    top: 0;
    right: @input-padding-horizontal-base;
    bottom: 0;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: auto;
  }

  // ===================== Compact Item Styles =====================
  // .compact-item(@input-prefix-cls);
}

@import './search-input';
@import './rtl';
@import './IE11';
