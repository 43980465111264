// @import "~@sdesign/theme/index.css";

@badge-prefix-cls: ~'@{ant-prefix}-badge';

.@{badge-prefix-cls} {
    &-count {
        padding: 0 var(--sdesign-spacing-small);
        color: var(--sdesign-color-white);
        font-size: var(--sdesign-font-size-extra-small);
        font-weight: var(--sdesign-font-weight-regular);
        box-shadow: 0 0 0 1px var(--sdesign-color-white);
        border-radius: var(--sdesign-radius-full);
    }

    &-multiple-words {
        padding: 0 var(--sdesign-spacing-small);
    }
}