@import "../utils.less";

.@{ant-prefix}-select {
  &:not(.@{ant-prefix}-select-customize-input) .@{ant-prefix}-select-selector {
    .border-radius(var(--sdesign-radius-medium));
  }

  &-lg {
    font-size: @font-size-base;
  }

  &-sm {
    font-size: @font-size-sm;
  }
}

.@{ant-prefix}-select-single:not(.@{ant-prefix}-select-customize-input) .@{ant-prefix}-select-selector {
  padding: 0 var(--sdesign-spacing-medium);
}

.@{ant-prefix}-select-open {
  .@{ant-prefix}-select-arrow {
    .anticon-down {
      transform: rotate(-180deg);
    }
  }
}

.@{ant-prefix}-select-arrow {
  color: #999999;
  right: 12px;
  justify-content: end;
  width: 12px;

  &.anticon {
    font-size: calc(var(--sdesign-font-size-extra-small) - 4px);
  }

  &.anticon-down {
    transform: rotate(0);
    transition: transform .3s;
  }
}

.@{ant-prefix}-select-multiple .@{ant-prefix}-select-selection-item {
  border: none;
}


.@{ant-prefix}-select-item-group {
  padding: var(--sdesign-spacing-medium) var(--sdesign-spacing-regular);
}

.@{ant-prefix}-select-item-option-grouped {
  padding-left: var(--sdesign-spacing-regular);
}

.@{ant-prefix}-select-show-search {
  .@{ant-prefix}-select-arrow {
    .anticon {
      font-size: var(--sdesign-font-size-small);
    }
  }

}

.@{ant-prefix}-select-dropdown {
  border-radius: var(--sdesign-radius-large);
  padding: var(--sdesign-spacing-small);
}

.rc-virtual-list-holder {
  padding: var(sdesign-spacing-small);

  .@{ant-prefix}-select-item-option-active {
    background-color: var(--sdesign-color-fill-hover);
    color: var(--sdesign-color-brand-5);
    border-radius: var(--sdesign-radius-medium);
  }

  .@{ant-prefix}-select-item-option-selected {
    color: var(--sdesign-color-brand-6);
    background-color: var(--sdesign-color-border-none)
  }
}