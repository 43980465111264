.@{ant-prefix}-drawer-content-wrapper {
  .@{ant-prefix}-drawer-content {
    .@{ant-prefix}-drawer-wrapper-body {
      .@{ant-prefix}-drawer-header {
        position: relative;
        border-bottom: none;

        &::after {
          content: '';
          width: calc(100% - 48px);
          height: 1px;
          background: var(--sdesign-color-grey-3);
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
        }


        &-title {
          flex-direction: row-reverse;
        }

        .@{ant-prefix}-drawer-close {
          margin-right: 0;
          margin-left: var(--sdesign-spacing-regular);
        }
      }

      .@{ant-prefix}-drawer-body {
        display: flex;
        flex-direction: column;

        .drawer-content {
          flex: auto;
          overflow-y: auto;
        }

        .drawer-footer {
          margin-top: 16px;
          margin-left: auto;
        }
      }
    }
  }
}