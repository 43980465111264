// @import "~@sdesign/theme/index.css";
@import './mixin.less';
@import './size.less';
@import './step-operator.less';


.@{stepper-prefix-cls} {
    width: 100%;

    /* 报错样式调整 */
    &:is(&-out-of-range) {
        border-color: var(--sdesign-color-status-danger);
    }

    &-input:hover {
        &+.sf-input-number-close {
            .anticon {
                display: flex;
            }
        }
    }

    .sf-input-number-close {
        .anticon {
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 28px;
            transform: translateY(-50%);
            background: var(--sdesign-font-color-5);
            height: 14px;
            width: 14px;
            font-size: 10px;
            border-radius: 12px;
            color: #ffffff;
            display: none;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            .anticon {
                background: var(--sdesign-color-grey-6);
                display: flex;
            }
        }
    }
}