// @import 'antd/es/style/themes/index';
// @import 'antd/es/style/mixins/index';
// @import 'antd/es/input/style/mixin';
@import './status';

@picker-prefix-cls: ~'@{ant-prefix}-picker';

.picker-padding(@input-height, @font-size, @padding-horizontal) {
  // font height probably 22.0001， So use floor better
  @font-height: floor(@font-size * @line-height-base) + 2;
  @padding-top: max(((@input-height - @font-height) / 2), 0);
  @padding-bottom: max(@input-height - @font-height - @padding-top, 0);
  padding: @padding-top @padding-horizontal @padding-bottom;
}

.@{picker-prefix-cls} {
  // 修改过
  width: 100%;
  @arrow-size: @popover-arrow-width;

  // .reset-component();
  // .picker-padding(@input-height-base, @font-size-base, @input-padding-horizontal-base);
  // position: relative;
  // display: inline-flex;
  // align-items: center;
  // background: @picker-bg;
  // border: @border-width-base @border-style-base @select-border-color;
  // border-radius: @control-border-radius;
  // transition: border @animation-duration-slow, box-shadow @animation-duration-slow;

  &:hover,
  &-focused {
    // .hover();
  }

  &-focused {
    // .active();
  }

  &&-disabled {
    // background: @input-disabled-bg;
    // border-color: @select-border-color;
    // cursor: not-allowed;
  }

  &&-disabled &-suffix {
    // color: @disabled-color;
  }

  &&-borderless {
    // background-color: transparent !important;
    // border-color: transparent !important;
    // box-shadow: none !important;
  }

  // ======================== Input =========================
  &-input {
    // position: relative;
    // display: inline-flex;
    // align-items: center;
    // width: 100%;

    > input {
      // .input();
      // flex: auto;

      // Fix Firefox flex not correct:
      // https://github.com/ant-design/ant-design/pull/20023#issuecomment-564389553
      // min-width: 1px;
      // height: auto;
      // padding: 0;
      // background: transparent;

      // border: 0;

      &:focus {
        // box-shadow: none;
      }

      &[disabled] {
        // background: transparent;
        color: var(--sdesign-font-color-2);

        &::placeholder {
          color: var(--sdesign-color-grey-4);
        }
      }
    }

    &:hover {
      .@{picker-prefix-cls}-clear {
        // opacity: 1;
      }
    }

    &-placeholder {
      > input {
        // color: @input-placeholder-color;
      }
    }
  }

  // Size
  &-large {
    .picker-padding(@input-height-lg, @font-size-base, @input-padding-horizontal-lg);

    .@{picker-prefix-cls}-input > input {
      font-size: @font-size-base;
    }
  }

  &-small {
    // .picker-padding(@input-height-sm, @font-size-base, @input-padding-horizontal-sm);
    .@{picker-prefix-cls}-input > input {
      font-size: @font-size-sm;
    }
  }

  &-suffix {
    // display: flex;
    // flex: none;
    // align-self: center;
    // margin-left: (@padding-xs / 2);
    // color: @disabled-color;
    // line-height: 1;
    // pointer-events: none;

    > * {
      // vertical-align: top;

      &:not(:last-child) {
        // margin-right: 8px;
      }
    }
  }

  &-clear {
    // position: absolute;
    // top: 50%;
    // right: 0;
    // color: @disabled-color;
    // line-height: 1;
    // background: @component-background;
    // transform: translateY(-50%);
    // cursor: pointer;
    // opacity: 0;
    // transition: opacity @animation-duration-slow, color @animation-duration-slow;

    > * {
      // vertical-align: top;
    }

    &:hover {
      // color: @text-color-secondary;
    }
  }

  &-separator {
    // position: relative;
    // display: inline-block;
    // width: 1em;
    // height: @font-size-lg;
    // color: @disabled-color;
    // font-size: @font-size-lg;
    // vertical-align: top;
    // cursor: default;

    .@{picker-prefix-cls}-focused & {
      // color: @text-color-secondary;
    }

    .@{picker-prefix-cls}-range-separator & {
      .@{picker-prefix-cls}-disabled & {
        // cursor: not-allowed;
      }
    }
  }

  // ======================== Range =========================
  &-range {
    // 修改过
    width: 100%;
    // position: relative;
    // display: inline-flex;

    // Clear
    .@{picker-prefix-cls}-clear {
      // right: @input-padding-horizontal-base;
    }

    &:hover {
      .@{picker-prefix-cls}-clear {
        // opacity: 1;
      }
    }

    // Active bar
    .@{picker-prefix-cls}-active-bar {
      // bottom: -@border-width-base;
      // height: 2px;
      // margin-left: @input-padding-horizontal-base;
      // background: @primary-color;
      // opacity: 0;
      // transition: all @animation-duration-slow ease-out;
      // pointer-events: none;
    }

    &.@{picker-prefix-cls}-focused {
      .@{picker-prefix-cls}-active-bar {
        // opacity: 1;
      }
    }

    &-separator {
      // align-items: center;
      // padding: 0 @padding-xs;
      // line-height: 1;
    }

    &.@{picker-prefix-cls}-small {
      .@{picker-prefix-cls}-clear {
        // right: @input-padding-horizontal-sm;
      }

      .@{picker-prefix-cls}-active-bar {
        // margin-left: @input-padding-horizontal-sm;
      }
    }
  }

  // ======================= Dropdown =======================
  &-dropdown {
    // .reset-component();
    // position: absolute;
    // // Fix incorrect position of picker popup
    // // https://github.com/ant-design/ant-design/issues/35590
    // top: -9999px;
    // left: -9999px;
    // z-index: @zindex-picker;

    &-hidden {
      // display: none;
    }

    &-placement-bottomLeft {
      .@{picker-prefix-cls}-range-arrow {
        // top: (@arrow-size / 2) - (@arrow-size / 3) + 0.7px;
        // display: block;
        // transform: rotate(-135deg) translateY(1px);
      }
    }

    &-placement-topLeft {
      .@{picker-prefix-cls}-range-arrow {
        // bottom: (@arrow-size / 2) - (@arrow-size / 3) + 0.7px;
        // display: block;
        // transform: rotate(45deg);
      }
    }

    &.@{ant-prefix}-slide-up-enter.@{ant-prefix}-slide-up-enter-active&-placement-topLeft,
    &.@{ant-prefix}-slide-up-enter.@{ant-prefix}-slide-up-enter-active&-placement-topRight,
    &.@{ant-prefix}-slide-up-appear.@{ant-prefix}-slide-up-appear-active&-placement-topLeft,
    &.@{ant-prefix}-slide-up-appear.@{ant-prefix}-slide-up-appear-active&-placement-topRight {
      // animation-name: antSlideDownIn;
    }

    &.@{ant-prefix}-slide-up-enter.@{ant-prefix}-slide-up-enter-active&-placement-bottomLeft,
    &.@{ant-prefix}-slide-up-enter.@{ant-prefix}-slide-up-enter-active&-placement-bottomRight,
    &.@{ant-prefix}-slide-up-appear.@{ant-prefix}-slide-up-appear-active&-placement-bottomLeft,
    &.@{ant-prefix}-slide-up-appear.@{ant-prefix}-slide-up-appear-active&-placement-bottomRight {
      // animation-name: antSlideUpIn;
    }

    &.@{ant-prefix}-slide-up-leave.@{ant-prefix}-slide-up-leave-active&-placement-topLeft,
    &.@{ant-prefix}-slide-up-leave.@{ant-prefix}-slide-up-leave-active&-placement-topRight {
      // animation-name: antSlideDownOut;
    }

    &.@{ant-prefix}-slide-up-leave.@{ant-prefix}-slide-up-leave-active&-placement-bottomLeft,
    &.@{ant-prefix}-slide-up-leave.@{ant-prefix}-slide-up-leave-active&-placement-bottomRight {
      // animation-name: antSlideUpOut;
    }
  }

  &-dropdown-range {
    // padding: (@arrow-size * 2 / 3) 0;

    &-hidden {
      // display: none;
    }
  }

  // Time picker with additional style
  &-dropdown &-panel > &-time-panel {
    // padding-top: (@padding-xs / 2);
  }

  // ======================== Ranges ========================
  &-ranges {
    // margin-bottom: 0;
    // padding: (@padding-xs / 2) @padding-sm;
    // overflow: hidden;
    // line-height: @picker-text-height - 2 * @border-width-base - (@padding-xs / 2);
    // text-align: left;
    // list-style: none;

    > li {
      // display: inline-block;
    }

    // https://github.com/ant-design/ant-design/issues/23687
    .@{picker-prefix-cls}-preset > .@{ant-prefix}-tag-blue {
      font-size: 12px;
      color: #333333;
      background: #f5f5f5;
      border: none;
      padding: 0 12px;
      line-height: 28px;
      // color: @primary-color;
      // background: @primary-1;
      // border-color: @primary-3;
      // cursor: pointer;
    }

    .@{picker-prefix-cls}-ok {
      // float: right;
      // margin-left: @padding-xs;
    }
  }

  &-range-wrapper {
    // display: flex;
  }

  &-range-arrow {
    // position: absolute;
    // z-index: 1;
    // display: none;
    // width: @arrow-size;
    // height: @arrow-size;
    // margin-left: @input-padding-horizontal-base * 1.5;
    // box-shadow: 2px 2px 6px -2px fade(@black, 10%); // use spread radius to hide shadow over popover
    // transition: left @animation-duration-slow ease-out;
    // .roundedArrow(@arrow-size, 5px, @calendar-bg);
  }

  &-panel-container {
    border-radius: var(--sdesign-radius-large);

    // overflow: hidden;
    // vertical-align: top;
    // background: @calendar-bg;
    // border-radius: @border-radius-base;
    // box-shadow: @box-shadow-base;
    // transition: margin @animation-duration-slow;

    .@{picker-prefix-cls}-panels {
      // display: inline-flex;
      // flex-wrap: nowrap;
      // direction: ltr;
    }

    .@{picker-prefix-cls}-panel {
      border-right: 1px solid rgba(224, 224, 224, 0.6);

      &:last-child {
        border-right: none;
      }

      // vertical-align: top;
      // background: transparent;
      // border-width: 0 0 @border-width-base 0;
      // border-radius: 0;

      .@{picker-prefix-cls}-content,
      table {
        // text-align: center;
      }

      &-focused {
        // border-color: @border-color-split;
      }
    }
  }

  // ===================== Compact Item Styles =====================
  // .compact-item(@picker-prefix-cls, null, ~'@{picker-prefix-cls}-focused');
}

@import './panel';
@import './rtl';
