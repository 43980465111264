@import './mixin';

@input-prefix-cls: ~'@{ant-prefix}-input';

@input-wrapper-cls: @input-prefix-cls, ~'@{input-prefix-cls}-affix-wrapper';
each(@input-wrapper-cls, {
  .@{value} {
    &-status-error {
      .status-color(@value, @error-color, @error-color, @input-bg, @error-color-hover, @error-color-outline);
      .status-color-common(@input-prefix-cls, @error-color, @error-color, @input-bg, @error-color-hover, @error-color-outline);
    }

    &-status-warning {
      .status-color(@value, @warning-color, @warning-color, @input-bg, @warning-color-hover, @warning-color-outline);
      .status-color-common(@input-prefix-cls, @warning-color, @warning-color, @input-bg, @warning-color-hover, @warning-color-outline);
    }
  }
});

.@{input-prefix-cls}-textarea {
  &-status-error,
  &-status-warning,
  &-status-success,
  &-status-validating {
    &.@{input-prefix-cls}-textarea-has-feedback {
      .@{input-prefix-cls} {
        padding-right: 24px;
      }
    }
  }
}

.@{input-prefix-cls}-group-wrapper {
  &-status-error {
    .group-status-color(@input-prefix-cls, @error-color, @error-color);
  }

  &-status-warning {
    .group-status-color(@input-prefix-cls, @warning-color, @warning-color);
  }
}
