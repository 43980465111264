// @import "~@sdesign/theme/index.css";

@card-prefix-cls: ~'@{ant-prefix}-card';
@card-hoverable-hover-border: transparent;

.@{card-prefix-cls} {
  border-radius: var(--sdesign-radius-large);
  box-shadow: 0 0 0 1px var(--sdesign-shadow-2);

  &-bordered {
    border: 1px solid var(--sdesign-color-border-hover);
  }

  &-head {
    padding: 0 var(--sdesign-spacing-huge);
    font-weight: var(--sdesign-font-weight-medium);
    font-size: var(--sdesign-font-size-medium);
    color: var(--sdesign-font-color-1);
    border-bottom: 1px solid var(--sdesign-color-border-hover);

    &-title {
      padding: var(--sdesign-spacing-large) 0;
    }
  }

  &-extra {
    padding: var(--sdesign-spacing-large) 0;
    font-weight: var(--sdesign-font-weight-regular);
    font-size: var(--sdesign-font-size-regular);
  }

  &-body {
    padding: var(--sdesign-spacing-huge);
  }

  &-type-inner &-head {
    padding: 0 var(--sdesign-spacing-large);
    background: @card-hoverable-hover-border;

    &-title {
      padding: var(--sdesign-spacing-medium) 0;
      font-size: var(--sdesign-font-size-regular);
      font-weight: var(--sdesign-font-weight-medium);
      color: var(--sdesign-font-color-1);
    }
  }

  &-actions {
    border-radius: 0 0 var(--sdesign-radius-large) var(--sdesign-radius-large);
    border-top: 1px solid var(--sdesign-color-border-hover);

    &>li {
      &:not(:last-child) {
        border-right: 1px solid var(--sdesign-color-border-hover);

        .@{card-prefix-cls}-rtl & {
          border-left: 1px solid var(--sdesign-color-border-hover);
        }
      }
    }
  }

  &-type-inner &-body {
    padding: var(--sdesign-spacing-large);
  }

  &-type-inner &-extra {
    padding: var(--sdesign-spacing-medium) 0;
  }

  &.title-no-bordered {
    .@{card-prefix-cls}-head {
      border-bottom: none;
      min-height: 24px;

      &-title {
        padding-top: var(--sdesign-spacing-huge);
        padding-bottom: 0;
      }
    }

    .@{card-prefix-cls}-body {
      padding-top: var(--sdesign-spacing-large);
    }
  }
}