@anchor-border-width: 2px;

.@{ant-prefix}-anchor {
  position: relative;
  padding-left: @anchor-border-width;

  &-link {
    &-title {
      color: var(--sdesign-color-grey-9);

      &:not(&-active):hover {
        color: var(--sdesign-color-grey-11);
      }

      &:not(&-active):active {
        color: var(--sdesign-color-brand-7);
      }
    }
  }

  &-ink {
    &-ball {
      position: absolute;
      left: 50%;
      display: none;
      width: 1px;
      height: 16px;
      margin-top: -4px;
      background-color: @component-background;
      border: 1px solid @primary-color;
      border-radius: 2px;
      transform: translateX(-50%);
      transition: top 0.3s ease-in-out;

      &.@{ant-prefix}-anchor-ink-ball-visible {
        display: inline-block;
      }
    }
  }
}