.@{ant-prefix}-spin.@{ant-prefix}-spin-show-text .@{ant-prefix}-spin-text {
  font-weight: var(--sdesign-font-weight-regular);
  font-size: var(--sdesign-font-size-regular);
  color: var(--sdesign-font-color-1) ;
  padding-top: var(--sdesign-spacing-regular);
}

.@{ant-prefix}-spin.@{ant-prefix}-spin-show-text.spin-horizontal{

  .@{ant-prefix}-spin-text{
    padding-top: 0;
    margin-left: var(--sdesign-spacing-regular);
  }
}
