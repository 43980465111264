.@{ant-prefix}-select-dropdown {
  .@{ant-prefix}-cascader-menu {
    padding: 4px;
  }

  .@{ant-prefix}-cascader-menu-item-active {
    border-radius: 4px;
    color: var(--sdesign-color-brand-6);
    font-weight: var(--sdesign-font-weight-medium);
  }

  .@{ant-prefix}-cascader-menu-item {
    margin-top: var(--sdesign-spacing-small);

    .@{ant-prefix}-cascader-menu-item-keyword {
      color: var(--sdesign-color-brand-6)
    }

    &:hover {
      border-radius: 4px;
      color: var(--sdesign-color-brand-5);
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .@{ant-prefix}-cascader-checkbox-indeterminate .@{ant-prefix}-cascader-checkbox-inner {
    &:after {
      height: 2px !important;
      background-color: #fff;
    }
  }

  .@{ant-prefix}-cascader-checkbox-inner {
    border-width: 2px;
    border-radius: 4px;
  }

  &.@{ant-prefix}-cascader-dropdown {
    padding-left: 0;
    padding-right: 0;
  }
}