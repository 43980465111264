@transfer-prefix-cls: ~'@{ant-prefix}-transfer';

.@{transfer-prefix-cls} {
    &-list {

        &-content-item,
        &-header {
            margin: var(--sdesign-spacing-small);
            padding-left: var(--sdesign-spacing-regular);
            padding-right: var(--sdesign-spacing-regular);
        }

        &-header {
            height: 32px;
            border-bottom: 0px solid #f0f0f0;
            padding-top: var(--sdesign-spacing-small);
            padding-bottom: var(--sdesign-spacing-small);
            line-height: 32px;
        }

        &-body {
            border-top: 1px solid #f0f0f0;
        }



        &-content-item {
            border-radius: var(--sdesign-radius-medium);

            &:not(&-disabled) {
                &:hover {
                    color: var(--sdesign-color-brand-5);
                }

                &.@{transfer-prefix-cls}-list-content-item-checked {
                    &:hover {
                        background-color: var(--sdesign-color-brand-1);
                    }
                }
            }

            &-disabled {
                color: var(--sdesign-color-grey-5);
            }

            &-checked {
                background-color: transparent;
            }
        }

        &-body-search-wrapper {
            padding-top: var(--sdesign-spacing-regular);
            padding-left: var(--sdesign-spacing-regular);
            padding-right: var(--sdesign-spacing-regular);
            padding-bottom: 0;
        }

        &-body-not-found {
            margin-top: var(--sdesign-spacing-large);
        }
    }

    &-operation {
        margin: 0 var(--sdesign-spacing-large);

        .@{ant-prefix}-btn-icon-only.@{ant-prefix}-btn-sm {
            width: 32px;
            height: 32px;
        }

        .@{ant-prefix}-btn-primary[disabled] {
            background-color: var(--sdesign-color-bg-4);
            color: var(--sdesign-font-color-5);
            border: none;
        }

        .@{ant-prefix}-btn-primary:not([disabled]) {
            background-color: var(--sdesign-color-brand-6);
        }

        .@{ant-prefix}-btn-primary:not([disabled]):hover {
            background-color: var(--sdesign-color-brand-5);
        }

        .@{ant-prefix}-btn-primary:not([disabled]):active {
            background-color: var(--sdesign-color-brand-7);
        }

        .@{ant-prefix}-btn:first-child {
            margin-bottom: var(--sdesign-spacing-large)
        }

        .anticon {
            margin-right: 0px;
        }
    }

    &.hidden-down {
        .@{transfer-prefix-cls}-list {
            &-header-dropdown {
                display: none;
            }
        }
    }
}