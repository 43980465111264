@slider-prefix-cls: ~'@{ant-prefix}-slider';

.@{slider-prefix-cls}-track {
  background-image: linear-gradient(90deg, #7975ff 0%, #3355ff 79%);
}

.@{slider-prefix-cls}-handle {
  border-color: var(--sdesign-color-brand-6);
  width: 16px;
  height: 16px;
  margin-top: -6px;

  &:focus:not(:hover) {
    box-shadow: none;
    border-color: var(--sdesign-color-brand-6);
  }

  &:active {
    box-shadow: 0 0 6px 0 rgba(40, 99, 250, 1) !important;
  }

  &:hover {
    box-shadow: none;
    width: 18px;
    height: 18px;
    margin-top: -7px;
  }
}

.@{slider-prefix-cls}-disabled {
  .@{slider-prefix-cls}-track {
    background-color: #d6ddff !important;
    background-image: linear-gradient(90deg, #d6ddff 0%, #d6ddff 79%);
  }

  .@{slider-prefix-cls}-handle {
    box-shadow: none !important;
    border-color: #d6ddff !important;

    &:hover {
      width: 16px;
      height: 16px;
      margin-top: -6px;
    }
  }
}

.@{slider-prefix-cls}-mark-text {
  font-size: var(--design-font-size-extra-small);
  color: var(--sdesign-font-color-3);
  padding-top: 8px;

  &::before {
    content: '';
    display: block;
    width: 1px;
    height: 6px;
    background-color: #e2e2e2;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.@{slider-prefix-cls}-dot {
  display: none;
}

.@{slider-prefix-cls}-mark {
  top: 10px;
}