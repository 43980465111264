.@{ant-prefix}-radio {
  &-wrapper {
    .@{ant-prefix}-radio-inner {
      border-width: 2px;
    }

    &:not(.@{ant-prefix}-radio-wrapper-disabled) {
      &:hover {
        .@{ant-prefix}-radio-inner {
          border-color: var(--sdesign-primary-color-hover);
        }
      }

      &:active {
        .@{ant-prefix}-radio-inner {
          border-color: var(--sdesign-primary-color-active);
        }
      }
    }
  }

  &-checked {
    .@{ant-prefix}-radio-inner {
      border-width: 4px;
      transition: all 0.3s;

      &::after {
        width: 0;
      }
    }
  }

  &-input:focus+&-inner {
    box-shadow: none;
  }

  &-wrapper+&-wrapper-disabled {
    .@{ant-prefix}-radio-checked {
      .@{ant-prefix}-radio-inner {
        border-width: 4px;
        transition: all 0.3s;
        border-color: var(--sdesign-color-brand-3);

        &::after {
          width: 0;
        }
      }
    }
  }

  &-group.bg {
    --sdesign-radio-button-height: 24px;
    --sdesign-radio-button-line-height: 24px;
    padding: var(--sdesign-spacing-small);
    background-color: var(--sdesign-color-bg-4);
    border-radius: var(--sdesign-radius-medium);

    .@{ant-prefix}-radio-button-wrapper {
      background: transparent;
      padding: 0 var(--sdesign-spacing-medium);
      height: var(--sdesign-radio-button-height);
      line-height: var(--sdesign-radio-button-line-height);
      margin: 0 var(--sdesign-spacing-regular);
      border: none;
      border-radius: var(--sdesign-radius-medium);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &-checked {

        &:not([class*=' @{ant-prefix}-radio-button-wrapper-disabled']).@{ant-prefix}-radio-button-wrapper {
          background-color: var(--sdesign-color-white);


          &:first-child {
            border-right: none;
            border: none;
          }

          &:focus-within {
            box-shadow: none;
          }
        }
      }

      &:not(:first-child)::before {
        display: none;
      }
    }
  }
}