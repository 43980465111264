@menu-animation-duration-normal: 0.15s;
@menu-horizontal-line-height: 32px;
@menu-item-group-height: 32px;
@menu-prefix-cls: ~'@{ant-prefix}-menu';
@menu-inline-submenu-bg: transparent;
@menu-dark-bg: var(--sdesign-color-grey-12);

.accessibility-focus() {
  box-shadow: 0 0 0 2px @primary-2;
}

.@{menu-prefix-cls} {
  padding: 12px 5px;
  padding-bottom: 12px;
  color: var(--sdesign-color-grey-9);

  &-submenu,
  &-submenu-inline {
    border-radius: 4px;
  }

  &-submenu {
    .@{menu-prefix-cls} {
      border-radius: var(--sdesign-radius-large);
    }
  }

  &-submenu-selected {
    .@{menu-prefix-cls}-submenu-arrow {
      &::before {
        background-color: @primary-color;
      }

      &::after {
        background-color: @primary-color;
      }
    }
  }

  &-vertical-right {
    border-left: @border-width-base @border-style-base @border-color-split;
  }

  &-vertical&-sub,
  &-vertical-left&-sub,
  &-vertical-right&-sub {
    min-width: 160px;
    max-height: calc(100vh - 100px);
    padding: 0;
    overflow: hidden;
    border-right: 0;

    &:not([class*='-active']) {
      overflow-x: hidden;
      overflow-y: auto;
    }

    .@{menu-prefix-cls}-item {
      left: 0;
      margin-left: 0;
      border-right: 0;

      &::after {
        border-right: 0;
      }
    }

    >.@{menu-prefix-cls}-item,
    >.@{menu-prefix-cls}-submenu {
      transform-origin: 0 0;
    }
  }

  &-item {
    border-radius: 4px;
  }

  &-sub&-inline {
    & .@{menu-prefix-cls}-item-group-title {
      padding-left: 32px;
    }
  }

  &-horizontal {
    line-height: @menu-horizontal-line-height;
    border-bottom: none;

    &:not(.@{menu-prefix-cls}-dark) {

      >.@{menu-prefix-cls}-item,
      >.@{menu-prefix-cls}-submenu {
        margin-top: -1px;
        margin-bottom: 0;
        padding: @menu-item-padding;

        &:hover,
        &-open,
        &-active {
          &::after {
            border-bottom: none;
          }
        }

        &:active {
          &::after {
            border-bottom: none;
          }
        }

        &-selected {
          &::after {
            border-bottom: none;
          }
        }

        &::after {
          border-bottom: none;
        }
      }
    }

    >.@{menu-prefix-cls}-submenu>.@{menu-prefix-cls}-submenu-title {
      padding: 0;
    }

    >.@{menu-prefix-cls}-item {
      a {
        &::before {
          bottom: -2px;
        }
      }
    }

    &::after {
      display: block;
      clear: both;
      height: 0;
      content: '\20';
    }
  }

  &-vertical,
  &-vertical-left,
  &-vertical-right,
  &-inline {
    .@{menu-prefix-cls}-item {
      &::after {
        display: none;
      }
    }
  }

  &-vertical {
    border-right: 0;

    .@{menu-prefix-cls}-item-group-list .@{menu-prefix-cls}-submenu-title,
    .@{menu-prefix-cls}-submenu-title {
      padding-right: 34px;
    }
  }
}

.@{ant-prefix}-menu-vertical.@{ant-prefix}-menu-sub .@{ant-prefix}-menu-item {
  line-height: @menu-item-group-height;
  height: @menu-item-group-height;
  border-radius: 4px;
  margin-left: 4px;
  margin-right: 4px;
}

/*----# light #-----*/
/*-- color --*/
.@{menu-prefix-cls}-light .@{menu-prefix-cls}-item {
  font-family: PingFangSC-Regular;
  font-size: var(--sdesign-font-size-regular);
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-item:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):hover {
  color: var(--sdesign-color-brand-5);
  background-color: var(--sdesign-color-brand-1);
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-active:not(.@{menu-prefix-cls}-submenu-selected) {
  background-color: transparent;

  &>.@{menu-prefix-cls}-submenu-title {
    background-color: var(--sdesign-color-brand-1);
  }
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-active:not(.@{menu-prefix-cls}-submenu-selected):active {

  &>.@{menu-prefix-cls}-submenu-title {
    background-color: var(--sdesign-color-brand-2);
  }
}


.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-submenu-active:not(.@{menu-prefix-cls}-submenu-selected) {
  background-color: transparent;

  &>.@{menu-prefix-cls}-submenu-title {
    background-color: var(--sdesign-color-grey-10);
  }

  &.@{menu-prefix-cls}-submenu-inline>.@{menu-prefix-cls}-submenu-title:active {
    background-color: var(--sdesign-color-grey-11);
  }
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-active,
.@{menu-prefix-cls}-inline .@{menu-prefix-cls}-submenu {
  padding-bottom: 0;
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 4px;
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 4px;
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-title:hover {
  color: var(--sdesign-color-brand-6);
  background-color: var(--sdesign-color-brand-1);
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-item:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):active,
.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-title:active {
  color: var(--sdesign-color-brand-7);
  background-color: var(--sdesign-color-brand-2);
}

// selected
.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-horizontal.@{menu-prefix-cls}-submenu-selected .@{menu-prefix-cls}-submenu-title:hover {
  color: var(--sdesign-color-white);
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-vertical.@{menu-prefix-cls}-submenu-selected .@{menu-prefix-cls}-submenu-title:hover {
  background-color: transparent;
  color: var(--sdesign-color-white);
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-item-selected {
  color: var(--sdesign-color-white);
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-item.@{menu-prefix-cls}-item-selected {
  color: var(--sdesign-color-white);
}

// horizontal
.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item-selected a:hover {
  color: var(--sdesign-color-white);
}

.@{menu-prefix-cls}-submenu-horizontal.@{menu-prefix-cls}-submenu-selected {
  color: var(--sdesign-color-white);
  background-color: var(--sdesign-color-brand-6) !important;

  &>.@{menu-prefix-cls}-submenu-title {
    color: var(--sdesign-color-white);
    background-color: var(--sdesign-color-brand-6);
  }
}

.@{menu-prefix-cls}.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-submenu.@{menu-prefix-cls}-submenu-horizontal {
  padding-right: 20px;

  &.@{menu-prefix-cls}-submenu-open {
    .sf-menu-submenu-arrow {
      transform: translateY(-4px);

      &::before {
        transform: rotate(45deg) translateX(2.5px);
      }

      &::after {
        transform: rotate(-45deg) translateX(-2.5px);
      }
    }
  }
}

.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-horizontal.@{menu-prefix-cls}-submenu-active {
  background-color: var(--sdesign-color-brand-1);

  &:active {
    background-color: var(--sdesign-color-brand-2);
  }
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-submenu-horizontal.@{menu-prefix-cls}-submenu-active {
  background-color: var(--sdesign-color-grey-10);
}

.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-inline:not(.@{menu-prefix-cls}-inline-collapsed) .@{menu-prefix-cls}-submenu:not(.@{menu-prefix-cls}-item-disabled)>.@{menu-prefix-cls}-submenu-title:hover {
  color: var(--sdesign-color-grey-3);
  background-color: var(--sdesign-color-grey-10);
}

.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-inline:not(.@{menu-prefix-cls}-inline-collapsed) .@{menu-prefix-cls}-submenu:not(.@{menu-prefix-cls}-item-disabled)>.@{menu-prefix-cls}-submenu-title:active {
  color: var(--sdesign-color-white);
  background-color: var(--sdesign-color-grey-11);
}

.@{menu-prefix-cls}:not(.@{menu-prefix-cls}-horizontal) .@{menu-prefix-cls}-item.@{menu-prefix-cls}-item-selected {
  background-color: var(--sdesign-color-brand-6);
}

.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item a {
  color: var(--sdesign-color-grey-9);
}

.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item-selected a {
  color: var(--sdesign-color-white);
}

.@{menu-prefix-cls}-horizontal:not(.@{menu-prefix-cls}-dark)>.@{menu-prefix-cls}-item:hover {
  color: var(--sdesign-color-brand-5);
}

.@{menu-prefix-cls}-horizontal:not(.@{menu-prefix-cls}-dark)>.@{menu-prefix-cls}-item-selected:hover {
  color: var(--sdesign-color-white);
}

.@{menu-prefix-cls}-horizontal:not(.@{menu-prefix-cls}-dark)>.@{menu-prefix-cls}-submenu-selected,
.@{menu-prefix-cls}-horizontal:not(.@{menu-prefix-cls}-dark)>.@{menu-prefix-cls}-item-selected {
  color: var(--sdesign-color-white);
  background-color: var(--sdesign-color-brand-6);
}

.@{menu-prefix-cls}-submenu-vertical.@{menu-prefix-cls}-submenu-selected {
  color: var(--sdesign-color-white);
  background-color: var(--sdesign-color-brand-6) !important;
}

/*-- space --*/
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item,
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-submenu,
.@{menu-prefix-cls}-horizontal:not(.@{menu-prefix-cls}-dark)>.@{menu-prefix-cls}-item,
.@{menu-prefix-cls}-horizontal:not(.@{menu-prefix-cls}-dark)>.@{menu-prefix-cls}-submenu {
  padding: 0 var(--sdesign-spacing-regular);
}

.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item,
.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-submenu {
  margin-right: var(--sdesign-spacing-basic);
}

.@{menu-prefix-cls}-item .@{menu-prefix-cls}-item-icon+span,
.@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-item-icon+span,
.@{menu-prefix-cls}-item .anticon+span,
.@{menu-prefix-cls}-submenu-title .anticon+span {
  margin-left: var(--sdesign-spacing-regular);
}

.@{menu-prefix-cls}.@{menu-prefix-cls}-inline-collapsed>.@{menu-prefix-cls}-item,
.@{menu-prefix-cls}.@{menu-prefix-cls}-inline-collapsed>.@{menu-prefix-cls}-submenu {
  margin-left: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;

  .@{menu-prefix-cls}-item-icon {
    line-height: 0 !important;
  }
}

.@{menu-prefix-cls}-inline-collapsed.@{menu-prefix-cls}-vertical .@{menu-prefix-cls}-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}

.@{menu-prefix-cls}.@{menu-prefix-cls}-inline-collapsed {
  width: 48px;

  &>.@{menu-prefix-cls}-submenu>.@{menu-prefix-cls}-submenu-title {
    display: flex;
    align-items: center;
  }
}

.@{menu-prefix-cls}-item .@{menu-prefix-cls}-item-icon,
.@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-item-icon,
.@{menu-prefix-cls}-item .anticon,
.@{menu-prefix-cls}-submenu-title .anticon,
.@{menu-prefix-cls} {
  font-size: var(--sdesign-font-size-medium);
  min-width: var(--sdesign-spacing-large);
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), color 0.3s
}

.@{menu-prefix-cls} {
  font-size: var(--sdesign-font-size-regular);
  padding: 12px var(--sdesign-spacing-basic);
  font-family: PingFangSC-Regular;
}

/*----# dark #-----*/
/*-- color --*/
.@{menu-prefix-cls}.@{menu-prefix-cls}-dark {
  color: var(--sdesign-color-grey-5);
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item {
  font-family: PingFangSC-Regular;
  font-size: var(--sdesign-font-size-regular);
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):hover,
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):hover,
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-submenu:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):hover,
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-vertical>.@{menu-prefix-cls}-submenu:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):hover,
.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-submenu-active:not(.@{menu-prefix-cls}-submenu-selected):hover>.@{menu-prefix-cls}-submenu-title {
  color: var(--sdesign-color-grey-3);
  background-color: var(--sdesign-color-grey-10);
  border-radius: 4px;
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):active,
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):active,
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-submenu:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):active,
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-vertical>.@{menu-prefix-cls}-submenu:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):active,
.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-submenu-active:not(.@{menu-prefix-cls}-submenu-selected):active>.@{menu-prefix-cls}-submenu-title {
  color: var(--sdesign-color-white);
  background-color: var(--sdesign-color-grey-11);
}

.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-submenu.@{menu-prefix-cls}-item-disabled:hover,
.@{menu-prefix-cls}-vertical>.@{menu-prefix-cls}-submenu.@{menu-prefix-cls}-item-disabled:hover {
  background-color: transparent;
}

.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item.@{menu-prefix-cls}-item-disabled:hover {
  background-color: transparent;
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item:not(.@{menu-prefix-cls}-item-disabled):not(.@{menu-prefix-cls}-item-selected):active {
  color: var(--sdesign-color-white);
  background-color: var(--sdesign-color-grey-11);
}

.@{menu-prefix-cls}.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item-selected,
.@{menu-prefix-cls}-submenu-popup.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item-selected,
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-submenu-selected.@{menu-prefix-cls}-submenu:hover,
.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-vertical>.@{menu-prefix-cls}-submenu-selected.@{menu-prefix-cls}-submenu:hover {
  background-color: var(--sdesign-color-brand-6);
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item,
.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item-group-title,
.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item>a,
.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item>span>a {
  color: var(--sdesign-color-grey-5);
  font-size: var(--sdesign-font-size-regular);
}

.@{menu-prefix-cls}-inline .@{menu-prefix-cls}-item:not(:last-child) {
  margin-bottom: var(--sdesign-spacing-small);
}

.@{menu-prefix-cls}-inline {
  border-right: 0;

  .@{menu-prefix-cls}-item,
  .@{menu-prefix-cls}-submenu-title {
    width: 100%;
  }

  .@{menu-prefix-cls}-item {
    padding: 0 12px;
  }
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-inline.@{menu-prefix-cls}-sub {
  background: var(--sdesign-color-grey-12);
}

// selected
.@{menu-prefix-cls}-submenu-popup.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item-selected:hover {
  background-color: var(--sdesign-color-brand-6);
}

.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item.@{menu-prefix-cls}-item-selected:hover {
  background-color: var(--sdesign-color-brand-6);
}

.@{menu-prefix-cls}-dark>.@{menu-prefix-cls}-item.@{menu-prefix-cls}-item-selected {
  background-color: var(--sdesign-color-brand-6);
}

.@{menu-prefix-cls}-dark.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-item.@{menu-prefix-cls}-item-selected {
  background-color: var(--sdesign-color-brand-6);
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item.@{menu-prefix-cls}-item-selected>span>a {
  color: var(--sdesign-color-white);
}

.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-item.@{menu-prefix-cls}-item-selected {
  color: var(--sdesign-color-white);
}

.@{menu-prefix-cls}-submenu>.@{menu-prefix-cls}-submenu-title:hover>.@{menu-prefix-cls}-submenu-expand-icon,
.@{menu-prefix-cls}-submenu>.@{menu-prefix-cls}-submenu-title:hover>.@{menu-prefix-cls}-submenu-arrow {

  &::before,
  &::after {
    background-color: var(--sdesign-color-brand-5);
  }
}

.@{menu-prefix-cls}-submenu.@{menu-prefix-cls}-submenu-active>.@{menu-prefix-cls}-submenu-title>.@{menu-prefix-cls}-submenu-expand-icon,
.@{menu-prefix-cls}-submenu.@{menu-prefix-cls}-submenu-active>.@{menu-prefix-cls}-submenu-title>.@{menu-prefix-cls}-submenu-arrow {

  &::before,
  &::after {
    background-color: var(--sdesign-color-brand-5);
  }
}

.@{menu-prefix-cls}-submenu.@{menu-prefix-cls}-submenu-selected>.@{menu-prefix-cls}-submenu-title>.@{menu-prefix-cls}-submenu-expand-icon,
.@{menu-prefix-cls}-submenu.@{menu-prefix-cls}-submenu-selected>.@{menu-prefix-cls}-submenu-title>.@{menu-prefix-cls}-submenu-arrow {

  &::before,
  &::after {
    background-color: var(--sdesign-color-brand-6);
  }
}

.@{menu-prefix-cls}-submenu>.@{menu-prefix-cls}-submenu-title>.@{menu-prefix-cls}-submenu-arrow {
  right: 8px;
  top: calc(50% + 1px);

  &::before,
  &::after {
    background-color: var(--sdesign-color-grey-9);
  }
}

.@{menu-prefix-cls}.@{menu-prefix-cls}-dark:not(.@{menu-prefix-cls}-inline-collapsed) .@{menu-prefix-cls}-submenu>.@{menu-prefix-cls}-submenu-title {
  color: var(--sdesign-color-grey-5);

  &>.@{menu-prefix-cls}-submenu-arrow {
    opacity: 1;

    &::before,
    &::after {
      background-color: var(--sdesign-color-grey-5);
    }
  }
}

.@{menu-prefix-cls}.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-submenu:not(.@{menu-prefix-cls}-submenu-selected)>.@{menu-prefix-cls}-submenu-title:hover {
  color: var(--sdesign-color-grey-3);

  &>.@{menu-prefix-cls}-submenu-arrow {

    &::before,
    &::after {
      background-color: var(--sdesign-color-grey-3);
    }
  }
}

.@{menu-prefix-cls}.@{menu-prefix-cls}-dark:not(.@{menu-prefix-cls}-inline-collapsed) .@{menu-prefix-cls}-submenu.@{menu-prefix-cls}-submenu-selected>.@{menu-prefix-cls}-submenu-title {
  color: var(--sdesign-color-white);

  &>.@{menu-prefix-cls}-submenu-arrow {
    opacity: 1;

    &::before,
    &::after {
      background-color: var(--sdesign-color-white);
    }
  }
}

.@{menu-prefix-cls}-submenu-popup {
  .@{menu-prefix-cls}-submenu {
    margin-left: 4px;
    margin-right: 4px;
  }

  .@{menu-prefix-cls}-submenu>.@{menu-prefix-cls}-submenu-title {
    line-height: 32px;
    height: 32px;
    border-radius: 4px;
    margin: 0 4px;

    &>.@{menu-prefix-cls}-submenu-arrow {
      top: calc(50%);
    }
  }

  .@{menu-prefix-cls}-sub {
    .@{menu-prefix-cls}-submenu-selected {
      color: var(--sdesign-color-brand-6);
      background-color: transparent !important;

      &:hover {
        &>.@{menu-prefix-cls}-submenu-title {
          background-color: var(--sdesign-color-brand-1) !important;
        }
      }

      &:active {
        &>.@{menu-prefix-cls}-submenu-title {
          background-color: var(--sdesign-color-brand-2) !important;
        }
      }
    }

    .@{menu-prefix-cls}-item {
      margin-bottom: 4px;
    }

    li {
      margin-bottom: 4px;
    }
  }

  &.@{menu-prefix-cls}-dark {
    .@{menu-prefix-cls}-sub {
      .@{menu-prefix-cls}-submenu-selected {
        color: var(--sdesign-color-white);
        background-color: transparent !important;

        &:hover {
          &>.@{menu-prefix-cls}-submenu-title {
            background-color: var(--sdesign-color-grey-10) !important;
          }
        }

        &:active {
          &>.@{menu-prefix-cls}-submenu-title {
            background-color: var(--sdesign-color-grey-11) !important;
          }
        }
      }
    }
  }
}

.@{menu-prefix-cls}-submenu-popup.@{menu-prefix-cls}-light .@{menu-prefix-cls}-submenu-vertical.@{menu-prefix-cls}-submenu-selected .@{menu-prefix-cls}-submenu-title {
  font-weight: var(--sdesign-font-weight-medium);

  &:hover {
    background-color: transparent;
    color: var(--sdesign-color-brand-6);
  }
}

.@{menu-prefix-cls}-inline.@{menu-prefix-cls}-root .@{menu-prefix-cls}-item>*:first-child,
.@{menu-prefix-cls}-inline.@{menu-prefix-cls}-root .@{menu-prefix-cls}-submenu-title>*:first-child {
  margin-left: -8px;
}

.sf-menu-submenu-arrow {
  position: absolute;
  top: calc(50% + 1px);
  margin-left: 6px;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &::before,
  &::after {
    position: absolute;
    width: 6px;
    height: 1.5px;
    background-color: currentcolor;
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }

  &::before {
    transform: rotate(-45deg) translateX(2.5px);
  }

  &::after {
    transform: rotate(45deg) translateX(-2.5px);
  }
}

.@{menu-prefix-cls}-submenu-open.@{menu-prefix-cls}-submenu-inline>.@{menu-prefix-cls}-submenu-title>.@{menu-prefix-cls}-submenu-arrow {
  transform: translateY(-4px);
}

.@{menu-prefix-cls}-vertical .@{menu-prefix-cls}-submenu,
.@{menu-prefix-cls}-vertical-left .@{menu-prefix-cls}-submenu,
.@{menu-prefix-cls}-vertical-right .@{menu-prefix-cls}-submenu,
.@{menu-prefix-cls}-inline .@{menu-prefix-cls}-submenu {
  padding-bottom: 0;
  margin: 4px 0;
}

.@{menu-prefix-cls} {
  transition: width 0s;
}

.@{menu-prefix-cls}-horizontal .@{menu-prefix-cls}-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.@{menu-prefix-cls}-submenu,
.@{menu-prefix-cls}-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &.@{menu-prefix-cls}-submenu-open {
    transition: border-color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.@{menu-prefix-cls}-submenu .@{menu-prefix-cls}-sub {
  cursor: initial;
  transition: background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.@{menu-prefix-cls}-title-content {
  transition: color 0.3s;
}

.@{menu-prefix-cls}-horizontal .@{menu-prefix-cls}-item,
.@{menu-prefix-cls}-horizontal .@{menu-prefix-cls}-submenu-title {
  transition: border-color 0.3s;
}

.@{menu-prefix-cls}-item,
.@{menu-prefix-cls}-submenu-title {
  transition: border-color 0s, background 0s
}

.@{menu-prefix-cls}-item .@{menu-prefix-cls}-item-icon,
.@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-item-icon,
.@{menu-prefix-cls}-item .anticon,
.@{menu-prefix-cls}-submenu-title .anticon {
  transition: color 0.3s;
}

.@{menu-prefix-cls}-item .@{menu-prefix-cls}-item-icon+span,
.@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-item-icon+span,
.@{menu-prefix-cls}-item .anticon+span,
.@{menu-prefix-cls}-submenu-title .anticon+span {
  transition: opacity 0s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.@{menu-prefix-cls}-submenu>.@{menu-prefix-cls}-submenu-title::after {
  transition: none;
}

.@{menu-prefix-cls}-horizontal>.@{menu-prefix-cls}-submenu::after {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.@{menu-prefix-cls}-vertical .@{menu-prefix-cls}-item::after,
.@{menu-prefix-cls}-vertical-left .@{menu-prefix-cls}-item::after,
.@{menu-prefix-cls}-vertical-right .@{menu-prefix-cls}-item::after,
.@{menu-prefix-cls}-inline .@{menu-prefix-cls}-item::after {
  transition: none;
}

.@{menu-prefix-cls}-inline .@{menu-prefix-cls}-selected::after,
.@{menu-prefix-cls}-inline .@{menu-prefix-cls}-item-selected::after {
  transition: none;
}

.@{menu-prefix-cls}-inline.@{menu-prefix-cls}-root .@{menu-prefix-cls}-item,
.@{menu-prefix-cls}-inline.@{menu-prefix-cls}-root .@{menu-prefix-cls}-submenu-title {
  transition: border-color 0.3s;
}

.@{menu-prefix-cls}-submenu-expand-icon,
.@{menu-prefix-cls}-submenu-arrow {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0s;
}

.@{menu-prefix-cls}.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-submenu-arrow,
.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-sub .@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-submenu-arrow,
.@{menu-prefix-cls}.@{menu-prefix-cls}-dark .@{menu-prefix-cls}-sub .@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-submenu-arrow {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0s;
}