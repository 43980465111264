@import './mixin.less';

.@{stepper-prefix-cls} {
    input {
        height: @input-height-base;
        line-height: @input-height-base;
        padding: 0 var(--sdesign-spacing-extra-huge) 0 var(--sdesign-spacing-medium);
    }

    /* 设计要求暂时写死 */
    &-lg {
        font-size: @font-size-base;

        input {
            height: @input-height-lg;
            line-height: @input-height-lg;
        }
    }

    &-sm {
        input {
            height: @input-height-sm;
            line-height: @input-height-sm;
            padding: 0 var(--sdesign-spacing-extra-huge) 0 var(--sdesign-spacing-regular);
        }
    }
}