@sdesign-dropdown-menu-bgcolor: var(--sdesign-color-brand-1);

.@{ant-prefix}-dropdown {
  padding: var(--sdesign-spacing-small);
}

.@{ant-prefix}-dropdown,
.@{ant-prefix}-dropdown-menu {
  .@{ant-prefix}-dropdown-menu {
    border-radius: 8px;
  }

  .@{ant-prefix}-dropdown-menu-item.@{ant-prefix}-dropdown-menu-item-active,
  .@{ant-prefix}-dropdown-menu-item {
    &:hover {
      background-color: @sdesign-dropdown-menu-bgcolor;
      color: var(--sdesign-color-brand-5);
    }

    &:active {
      background-color: var(--sdesign-color-brand-2);
      color: var(--sdesign-color-brand-6);
    }

  }

  .@{ant-prefix}-dropdown-menu-item.@{ant-prefix}-dropdown-menu-item-disabled:hover {
    background-color: #fff;
    color: #b8b8b8;
  }

  .@{ant-prefix}-dropdown-menu-submenu-title,
  .@{ant-prefix}-dropdown-menu-item {
    font-size: var(--sdesign-font-size-regular);
    font-weight: var(--sdesign-font-weight-regular);
    margin: var(--sdesign-spacing-basic);
    border-radius: var(--sdesign-radius-medium);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .@{ant-prefix}-dropdown-menu-submenu-title {
      transition: all 0.1s;
    }
  }

  .@{ant-prefix}-dropdown-menu-item-selected {
    background-color: var(--sdesign-color-brand-2);
    color: var(--sdesign-color-brand-6);
  }
}

.@{ant-prefix}-dropdown-trigger {
  .@{ant-prefix}-btn:disabled {
    color: var(--sdesign-color-grey-5);
    background: var(--sdesign-color-fill-3);
    border-color: var(--sdesign-color-grey-3);

    &:hover {
      color: var(--sdesign-color-grey-5);
      background: var(--sdesign-color-fill-3);
      border-color: var(--sdesign-color-grey-3);
    }

    &.@{ant-prefix}-btn-text {
      &:hover {
        color: var(--sdesign-color-grey-5);
        background: var(--sdesign-color-white);
      }

      background: var(--sdesign-color-white);
      border-color: transparent;
    }
  }
}