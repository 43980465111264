.@{ant-prefix}-pro-page-container {
  &-warp {
    .@{ant-prefix}-page-header {
      padding: 14px 20px;

      &-heading-title {
        font-size: 18px;
        font-weight: 600;
      }
    }  
  }

  &-children-content {
    margin: 16px 20px 0;
  }
}