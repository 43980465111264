.@{ant-prefix}-popover {
  .@{ant-prefix}-popover-content {
    background-color: var(--sdesign-color-fill-1);
    border-radius: var(--sdesign-radius-large);

    .@{ant-prefix}-popover-arrow {
      width: 16px;
      height: 16px;
    }

    .@{ant-prefix}-popover-message-icon {
      padding-top: var(--sdesign-spacing-extra-small);
    }

    .@{ant-prefix}-popover-inner {
      border-radius: var(--sdesign-radius-large);
      padding: var(--sdesign-spacing-medium) var(--sdesign-spacing-large) var(--sdesign-spacing-extra-large);
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.09);

      .@{ant-prefix}-popover-title {
        border-bottom: none;
        font-size: var(--sdesign-font-size-regular);
        font-weight: var(--sdesign-font-weight-medium);
        color: var(--sdesign-font-color-1);
        min-width: 0;
        padding: 0;

        span {
          display: flex;
          align-items: center;
        }
      }

      .@{ant-prefix}-popover-inner-content {
        overflow: auto;
        font-size: var(--sdesign-font-size-extra-small);
        font-weight: var(--sdesign-font-weight-regular);
        color: var(--sdesign-font-color-2);
        padding: 0;

        button>span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      // 无标题
      &>div:only-child {
        padding: 0;
      }
    }

    .@{ant-prefix}-popover-message {
      padding: 0;
    }

    .@{ant-prefix}-popover-buttons {
      margin-bottom: 0;
      margin-top: var(--sdesign-spacing-large);
    }

    .no-title-content,
    .icon-title {
      span {
        margin-right: var(--sdesign-spacing-small);
      }
    }
  }

  &.@{ant-prefix}-popconfirm {
    .@{ant-prefix}-popover-inner {
      padding: var(--sdesign-spacing-large) var(--sdesign-spacing-huge) var(--sdesign-spacing-extra-large);
    }
  }

  &.no-popover-title {
    .@{ant-prefix}-popover-inner {
      padding: var(--sdesign-spacing-medium) var(--sdesign-spacing-large);
    }
  }

  &.popover-had-icon {
    .@{ant-prefix}-popover-inner-content {
      margin-left: var(--sdesign-spacing-extra-large);
    }
  }
}